import Vue from '@vue/compat'
import Page from './components/Login.vue';
import eventBus from "@/Vue/common/eventBus";

Vue.config.productionTip = false

const page = new Vue({
    render: h => h(Page),
    mounted() {
        if (window.showComponent) {
            window.eventHub.$emit('hideLoginView');
            window.eventHub.$emit(window.showComponent);
        }
        if (window.showChangePassword) {
            window.eventHub.$emit(window.showChangePassword);
        }
    }
}).$mount('#login');


export default page;
