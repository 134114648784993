export default function(currency, country) {
    if (!currency) {
        currency = window.currencyIso || 'USD';
    }
    if (!country) {
        country = window.countryCode || 'US';
    }

    let formatter = new Intl.NumberFormat('en-' + country, {
        style: 'currency',
        currency,
    });

    const currencyPart = formatter.formatToParts(0).filter(part => 'currency' === part.type);
    if (currencyPart && currencyPart.length > 0) {
        return currencyPart[0]?.value;
    }

    return null;
}
