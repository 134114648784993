<template>
    <transition :name="myTransition">
        <div :id="myId" v-show="isShown" class="modal" :class="dialogClass" :style="{zIndex}">
            <div v-click-outside="clickedOutside" :class="['modal-dialog', {'full-screen': showFullscreen}]" :style="{width: `${width}px`}">
                <div ref="modalContent" class="modal-content" :style="computedModalContentStyle">
                    <div class="modal-header" v-if="showHeader">
                        <h4 class="modal-title">{{title}}</h4>
                        <button v-if="close" type="button" class="close" @click.prevent="onClose">
                            <span class="fa fa-close" />
                        </button>
                    </div>

                    <div id="modal-body" class="modal-body" :class="{'no-padding': bodyNoPadding}" :style="computedBodyStyle">
                        <slot />
                    </div>

                    <div v-if="footer" class="modal-footer">
                        <slot name="footer" :close="onClose">
                            <button type="button" class="btn btn-default" @click.prevent="onClose">
                                Cancel
                            </button>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>

import clickOutside from "src/Vue/common/directives/clickOutside";

export default {
    name: "Modal",
    directives: {clickOutside},
    props: {
        title: {type: String, default: ''},
        close: {type: Boolean, default: false},
        footer: {type: Boolean, default: false},
        width: {type: Number, default: 500},
        bodyMaxHeight: {type: Number, default: 80},
        bodyVerticalOverflow: {type: String, default: 'scroll'},
        bodyNoPadding: {type: Boolean, default: false},
        transition: {type: String, default: ''},
        zIndex: {type: Number, default: 1040},
        position: {type: String, default: 'right'},
        mountShowing: {type: Boolean, default: false},
        showFullscreen: {type: Boolean, default: false},
        runClickedOutsideDirective: {type: Boolean, default: true},
        id: {type: String, default: null},
        showHeader: {type: Boolean, default: true}
    },
    data() {
        return {
            isShown: false,
            visible: false,
        };
    },
    watch: {
        isShown(isShown, wasShown) {
            if (isShown === wasShown) {
                return;
            }

            this.$emit(isShown ? 'shown' : 'hidden');

            // This click outside event is probably what is showing the dialog, so don't let it also close the dialog
            setInterval(() => {
                this.visible = this.isShown;
            }, 1000);
        }
    },
    mounted() {
        if (this.$parent?.$on) {
            this.$parent.$on('showModal', this.onShow);
            this.$parent.$on('hideModal', this.onClose);
            this.$parent.$on('forward', this.onForward);
        }

        if (this.$on) {
            this.$on('forward', this.onForwardParent);
        }

        // Display the modal to control the visibility using v-if on parent
        if (this.mountShowing) {
            this.onShow();
        }

        if (this.showFullscreen || this.close) {
            window.addEventListener('keydown', this.closeDialog);
        }
    },
    unmounted() {
        if (this.$parent?.$off) {
            this.$parent.$off('showModal');
            this.$parent.$off('hideModal');
            this.$parent.$off('forward');
        }
        if (this.$off) {
            this.$off('forward');
        }
    },
    computed: {
        computedModalContentStyle() {
            if (this.showFullscreen) {
                return {};
            }
            return {
                width: `${this.width}px`,
                marginLeft: `-${this.width / 2}px`,
                left: '50%'
            };
        },
        computedBodyStyle() {
            if (this.showFullscreen) {
                return {};
            }

            return {
                maxHeight: `${this.bodyMaxHeight}vh`,
                overflowY: `${this.bodyVerticalOverflow}`
            };
        },
        dialogClass() {
            switch (this.position) {
                case 'right':
                    return 'onyx-modal';
                case 'full-screen':
                    return 'full-screen';
                default:
                    return 'bootstrap';
            }
        },
        myTransition() {
            if (!this.transition) {
                if (this.position === 'right') {
                    return 'slide';
                } else {
                    return  'fade';
                }
            }
            return this.transition;
        },
        myId() {
            let id = this.id;
            if (!id) {
                id = `input-${this._uid}`;
            }
            return id;
        }
    },
    methods: {
        closeDialog(event) {
            if (['Escape', 27].includes(event.keyCode)) {
                this.onClose();
            }
        },
        clickedOutside() {
            if (this.visible && this.runClickedOutsideDirective) {
                this.onClose();
            }
        },
        onClose() {
            this.$emit('clickedOutside');
            this.isShown = false;
            if (this.showFullscreen) {
                window.removeEventListener('keydown', this.closeDialog)
            }
        },
        onShow() {
            this.isShown = true;
        },
        onForward(event) {
            this.forward(this, event);
        },
        onForwardParent(event) {
            this.forward(this.$parent, event);
        },

        forward(component, event) {
            component.$emit(event?.name, event?.data);
        },
    },
};
</script>

<style lang="scss">
.full-screen {
    display: flex;
    background-color: #00000070;
    min-width: 100%;
    min-height: 100%;
    overflow: auto;
    margin: 0px!important;
}

.slide-enter-active, .slide-leave-active {
    .modal-dialog {
        transition: margin-right .3s ease-in-out, opacity .3s ease-in-out;
    }
    transition: opacity .3s;
}

.slide-enter, .slide-leave-to {
    .modal-dialog {
        opacity: 0;
        margin-right: -100%;
    }
    opacity: 0;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}

.modal.bootstrap {
    display: flex;
    justify-content: center;
    align-items: baseline;
    z-index: 1050;
    background-color: rgba(0, 0, 0, 0.5);

    .modal-dialog {
        top: unset;
        right: unset;
        left: unset;
        bottom: unset;
        padding: unset;
        border: unset;
        position: static;

        .modal-content {
            position: relative;
            border-radius: 5px !important;

            .modal-title {
                font-weight: bold;
            }

            .modal-body {
                position: relative;
                top: unset;
                right: unset;
                left: unset;
                bottom: unset;
            }

            .modal-footer {
                position: static;
                top: unset;
                right: unset;
                left: unset;
                bottom: unset;
                width: 100%;
            }
        }
    }

    .no-padding {
        padding: 0;
    }
}

.modal.onyx-modal {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 1040;
    background-color: rgba(0, 0, 0, 0.5);

    .modal-dialog {
        top: unset;
        right: unset;
        left: unset;
        bottom: unset;
        padding: unset;
        margin: 0;
        border: none;
        position: static;
        width: 100%;
        height: 100%;
        z-index: auto;

        .modal-content {
            position: relative;
            height: 100%;
            padding: 0px;

            .modal-body {
                height: 100%;
                position: relative;
                top: unset;
                right: unset;
                left: unset;
                bottom: unset;
                overflow: auto;
            }

            .modal-footer, .modal-header {

            }

            .modal-header {
                top: 0;
                left: 0;
                width: 100%;
            }

            .modal-footer {
                width: 100%;
            }
        }
    }

    .no-padding {
        padding: 0;
    }
}

.adyen-kyc-ui-element-container {
    padding: 0px !important;
}
</style>
