<template>
    <div class="alert" :class="alertClasses" role="alert" v-if="message">

        <div class="container">
            <div class="row">
                <div class="col-sm-11">
                    <strong v-if="type === 'error'">Error: </strong>
                    <strong v-if="type === 'warning'">Notice: </strong>
                    <a v-if="adyen_response_code" href="#" @click="handleEditInvalidField(adyen_response_code)">{{message}}</a>
                    <span v-else>{{ message }}</span>
                    <div v-if="details.length">
                        <br>
                        <ul>
                            <li v-for="detail in details">{{ detail }}</li>
                        </ul>
                    </div>
                </div>
                <div v-if="showClose" class="col-sm-1">
                    <button
                        type="button"
                        class="btn-close pull-right"
                        aria-label="Close"
                        @click.self.stop="message = null"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {getInvalidFieldIssueFromAdyenResponseCode} from "@/Vue/setup/util/adyenOnboardVerificationCodes";
import {useOnboardingStore} from "@/Vue/common/store/onboarding.state";

export default {
    name: "AlertDetails",
    props: {
        showClose: { type: Boolean, default: true }
    },
    data() {
        return {
            message: null,
            type: 'success',
            details: [],
            adyen_response_code: null
        };
    },
    computed: {
        alertClasses() {
            const classes = [];

            if (this.type === 'error') {
                classes.push('alert-danger');
            }

            if (this.type === 'success') {
                classes.push('alert-success');
            }

            if (this.type === 'warning') {
                classes.push('alert-warning');
            }

            return classes;
        },
    },
    methods: {
        handleEditInvalidField(adyenResponseCode) {
            if (adyenResponseCode) {
                const issue = getInvalidFieldIssueFromAdyenResponseCode(adyenResponseCode);
                if (issue) {
                    const onboardingState = useOnboardingStore();
                    onboardingState.setSelectedIssueToSolve(issue);
                    this.$emit('requestDataChange', issue);
                    this.message = null;
                }
            }
        },
        setSuccessMessage(message) {
            this.type = 'success';
            this.setMessage(message);
        },
        setErrorMessage(message, adyenResponseCode = null) {
            this.type = 'error';
            this.setMessage(message);
            this.adyen_response_code = adyenResponseCode
        },
        setWarningMessage(message) {
            this.type = 'warning';
            this.setMessage(message);
        },
        setMessage(message) {
            this.message = message;
        },
        addDetail(detail) {
            this.details.push(detail);
        },
        reset() {
            this.message = null;
            this.details = [];
        },
        hasErrorMessage() {
            return this.type === 'error' && (this.message || this.details.length);
        }
    }
}
</script>

<style scoped>

</style>