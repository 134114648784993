<template>
    <panel header="Change Password" header-type="h1" v-show="show">
        <error-details ref="errorDetails"></error-details>
        <password-input v-model="password1"></password-input>
        <password-input v-model="password2" label="Re-enter Password"></password-input>
        <div class="form-group">
            <action-button text="Update Password" @click="updatePassword()" ref="updatePassword"></action-button>
            <action-button text="Cancel" type="default" @click="close()"></action-button>
        </div>
    </panel>
</template>

<script>

import Modal from "src/Vue/common/components/Modal";
import Panel from "src/Vue/common/components/Panel";
import ErrorDetails from "src/Vue/common/components/AlertDetails";
import TextInput from "src/Vue/common/components/TextInput";
import PasswordInput from "src/Vue/common/components/PasswordInput";
import ActionButton from "src/Vue/common/components/ActionButton";
import axios from "src/Vue/common/utilities/http";
import eventBus from "../../common/eventBus";

export default {
    name: "ChangePassword",
    components: {Modal, Panel, TextInput, PasswordInput, ActionButton, ErrorDetails},
    data() {
        return {
            password1: null,
            password2: null,
            show: false,
        }
    },
    mounted() {
        window.eventHub.$on('showChangePassword', this.open);
    },
    methods: {
        updatePassword() {
            this.$refs.errorDetails.reset();
            if (!this.password1) {
                this.$refs.errorDetails.setErrorMessage('Password is empty, please enter a password.');
                return;
            }
            if (this.password1 !== this.password2) {
                this.$refs.errorDetails.setErrorMessage('Passwords do not match, try again.');
                return;
            }

            this.$refs.updatePassword.setInProgress(true);
            let params = {
                'new_password': this.password1,
            }
            axios.put('/api/user/update_password', params).then((result) => {
                this.$refs.updatePassword.setInProgress(false);
                if (result.data && result.data.error && result.data.error.message) {
                    this.$refs.errorDetails.setErrorMessage(result.data.error.message);
                    return;
                }
                window.location = '/home';
            }).catch((error) => {
                if(error.response?.data?.error?.message) {
                    this.$refs.errorDetails.setErrorMessage(error.response?.data?.error?.message);
                }
                this.$refs.updatePassword.setInProgress(false);
            })
        },
        open() {
            this.show = true;
        },
        close() {
            this.$emit('closedChangePassword');
            this.show = false;
        }
    }
}
</script>

<style scoped>

</style>
