export const bankAccountFileUploadInfo = {
    header: "Upload a bank statement",
    subText: "Please upload a file that meets the following conditions:",
    infos: [
        {
            text: "Dated within the last 12 months",
            popupInfo: "Document has a visible date within the last 12 months."
        },
        {
            text: "List an account holder name that matches the name of the business",
            popupInfo: "The legal business name or trade name (DBA) is acceptable."
        },
        {
            text: "Be issued from your banking institution",
            popupInfo: "Bank statements, checks, or screenshots of your online banking portal are recommended."
        },
        {
            text: "Account number or IBAN",
            popupInfo: "You can find your IBAN on your account statement."
        },
        {
            text: "Accepted file format",
            popupInfo: "Formats: JPEG, JPG, PNG, or PDF (maximum 2 pages) no larger than 4 MB"
        }
    ]
}

export const registrationDocumentFileUploadInfo = {
    header: "Upload a registration document",
    subText: "We couldn't verify your company information. Please upload a file that meets the following conditions:",
    infos: [
        {
            text: "Dated within the last 12 months",
            popupInfo: "Document has a visible date within the last 12 months."
        },
        {
            text: "Shows the business name and Business Number (BN)",
            popupInfo: "Tax Notice of Assessment is recommended."
        },
        {
            text: "Issued by local or federal organization",
            popupInfo: "Independent source such as the local commercial register of your country is recommended."
        }
    ]
}

export const registrationDocumentCanadaFileUploadInfo = {
    header: "Upload official documents to verify your business",
    subText: "Please upload official government-issued documents that validate the legal name of your business. The primary document types suitable for business verification include:",
    infos: [
        {
            text: "Certificate / Articles of Incorporation"
        },
        {
            text: "Confirmation of Registration for GST/HST (only for federally incorporated businesses"
        },
        {
            text: "Notice of assessment for GST/HST (only for federally incorporated businesses)"
        }
    ]
}

export const proofOfSignatoryFileUploadInfo = {
    header: "Proof of Registration/Association",
    subText: "Please upload document(s) that meets the requirements listed below:",
    infos: [
        {
            text: "Non-Profit registration (can be from Secretary of State or 941 form), with the name of the company and the job title of the person acting as a Signatory."
        },
        {
            text: "If the name of the Signatory is not listed in the above document then provide a document/letter from State of affairs confirming that the authorized signatory has authority to sign of behalf of the company."
        }
    ]
}

export const constitutionalDocumentFileUploadInfo = {
    header: "Upload a registration document",
    subText: "",
    infos: [
        {
            text: "Issued within the last 12 months, or it must contain a signature and a state of affairs with a date not older than 12 months",
            popupInfo: ""
        },
        {
            text: "The following are examples of documents accepted for sole proprietorships:",
            popupInfo: ""
        },
        {
            text: "Doing business as-name filing (also known as Fictitious- or Trade name filing)",
            popupInfo: "",
            subItem: true
        },
        {
            text: "Tax filing",
            popupInfo: "",
            subItem: true
        }
    ]
}

export const verificationCodes = window.verificationCodes ?? [
    {
        adyen_response_code: "1_1000",
        main_header: "Error 1_1000: Business type could not be verified",
        short_text: "Business type could not be verified",
        description: "Please contact Customer Service for further assistance",
        section: "company",
        fields: ['businessType']
    },
    {
        adyen_response_code: "1_1001",
        main_header: "Error 1_1001: Application submission failed",
        short_text: "Application submission failed",
        description: "Application could not be completed. Please try again or contact Customer Service for further assistance.",
        section: "company"
    },
    {
        adyen_response_code: "1_1002",
        main_header: "Error 1_1002: Information was missing",
        short_text: "Information was missing",
        description: "Please fill in the additional required fields.",
        section: "company"
    },
    {
        adyen_response_code: "1_1003",
        main_header: "Error 1_1003: Document was rejected to protect your privacy",
        short_text: "Document was rejected to protect your privacy",
        description: "The uploaded document was rejected for your protection due to containing sensitive information. Please upload a new document or contact Customer Service for further assistance.",
        section: "company"
    },
    {
        adyen_response_code: "1_1004",
        main_header: "Error 1_1004: Information was missing",
        short_text: "Information was missing",
        description: "Please fill in the additional required fields.",
        section: "company"
    },
    {
        adyen_response_code: "1_3000",
        main_header: "Error 1_3000: The user couldn't be verified",
        short_text: "The user couldn't be verified",
        description: "Please close the browser and try again.",
        section: "owners",
        errorInfos: registrationDocumentFileUploadInfo
    },
    {
        adyen_response_code: "1_3001",
        main_header: "Error 1_3001: Some personal details could not be verified",
        short_text: "Some personal details could not be verified",
        description: "We were not able to verify your name and date of birth. Please check the information and resubmit your application.",
        section: "owners",
        fields: ["firstName", "lastName", "birthDate"]
    },
    {
        adyen_response_code: "1_3002",
        main_header: "Error 1_3002: Some personal details could not be verified",
        short_text: "Some personal details could not be verified",
        description: "Some personal information could not be verified. Please check the information and resubmit your application.",
        section: "owners",
        fields: ['socialSecurityNumber']
    },
    {
        adyen_response_code: "1_3003",
        main_header: "Error 1_3003: Some personal details could not be verified",
        short_text: "Some personal details could not be verified",
        description: "Some personal information could not be verified. Please check the information and resubmit your application.",
        section: "owners",
        fields: ["stateOrProvince"]
    },
    {
        adyen_response_code: "1_3004",
        main_header: "Error 1_3004: Some personal details could not be verified",
        short_text: "Some personal details could not be verified",
        description: "Some personal information could not be verified. Please check the information and resubmit your application.",
        section: "owners",
        fields: ["country"],
        includeErrors: ["1_304", "1_501"], // When error 1_3004 occurs, include the errors inside this array.
        relatedTo: ["1_304"]
    },
    {
        adyen_response_code: "1_3005",
        main_header: "Error 1_3005: Some personal details could not be verified",
        short_text: "Some personal details could not be verified",
        description: "Some personal information could not be verified. Please check the information and resubmit your application.",
        section: "owners",
        fields: ['socialSecurityNumber']
    },
    {
        adyen_response_code: "1_3006",
        main_header: "Error 1_3006: Some personal details could not be verified",
        short_text: "Some personal details could not be verified",
        description: "Some personal information could not be verified. Please check the information and resubmit your application.",
        section: "owners",
        fields: ['socialSecurityNumber']
    },
    {
        adyen_response_code: "1_3007",
        main_header: "Error 1_3007: Some personal details could not be verified",
        short_text: "Some personal details could not be verified",
        description: "Some personal information could not be verified. Please check the information and resubmit your application.",
        section: "owners",
        fields: ['socialSecurityNumber']
    },
    {
        adyen_response_code: "1_3008",
        main_header: "Error 1_3008: The file uploaded could not be verified",
        short_text: "The file uploaded could not be verified",
        description: "The uploaded document was not able to be verified. Please check the file and make sure it isn't blurry, cut off, or incomplete",
        section: "owners"
    },
    {
        adyen_response_code: "1_3009",
        main_header: "Error 1_3009: The file uploaded could not be verified",
        short_text: "The file uploaded could not be verified",
        description: "The uploaded document was not able to be verified. Please check the file and make sure it isn't blurry, cut off, or incomplete",
        section: "owners"
    },
    {
        adyen_response_code: "1_3010",
        main_header: "Error 1_3010: The file uploaded could not be verified",
        short_text: "The file uploaded could not be verified",
        description: "The uploaded document was not able to be verified. Please check the file and make sure it isn't blurry, cut off, or incomplete",
        section: "owners"
    },
    {
        adyen_response_code: "1_3011",
        main_header: "Error 1_3011: The file uploaded could not be verified",
        short_text: "The file uploaded could not be verified",
        description: "The uploaded document was not able to be verified. Please check the file and make sure it isn't blurry, cut off, or incomplete",
        section: "owners"
    },
    {
        adyen_response_code: "1_3012",
        main_header: "Error 1_3012: The file uploaded could not be verified",
        short_text: "The file uploaded could not be verified",
        description: "The uploaded document was not able to be verified. Please check the file and make sure it isn't blurry, cut off, or incomplete",
        section: "owners"
    },
    {
        adyen_response_code: "1_3013",
        main_header: "Error 1_3013: Unsupported file type",
        short_text: "Unsupported file type",
        description: "The file format is not supported. Please upload a .JPG, .PNG, or .PDF",
        section: "owners"
    },
    {
        adyen_response_code: "1_3014",
        main_header: "Error 1_3014: The ID document image didn't contain the MRZ code",
        short_text: "The ID document image didn't contain the MRZ code",
        description: "The uploaded document was not able to be verified. Please check the file and make sure it isn't blurry, cut off, or incomplete",
        section: "owners"
    },
    {
        adyen_response_code: "1_3015",
        main_header: "Error 1_3015: The ID document was expired",
        short_text: "The ID document was expired",
        description: "The uploaded document was expired. Please submit a new file with an ID that is not expired.",
        section: "owners"
    },
    {
        adyen_response_code: "1_3016",
        main_header: "Error 1_3016: The file uploaded could not be verified",
        short_text: "The file uploaded could not be verified",
        description: "The uploaded document was not able to be verified. Please check the file and make sure it isn't blurry, cut off, or incomplete",
        section: "owners"
    },
    {
        adyen_response_code: "1_3017",
        main_header: "Error 1_3017: The file uploaded was incomplete",
        short_text: "The file uploaded was incomplete",
        description: "The uploaded document was not able to be verified. Please check the file and make sure it isn't blurry, cut off, or incomplete",
        section: "owners"
    },
    {
        adyen_response_code: "1_3018",
        main_header: "Error 1_3018: The file uploaded was incomplete",
        short_text: "The file uploaded was incomplete",
        description: "The uploaded document was not able to be verified. Please check the file and make sure it isn't blurry, cut off, or incomplete",
        section: "owners"
    },
    {
        adyen_response_code: "1_3019",
        main_header: "Error 1_3019: The ID document was expired",
        short_text: "The ID document was expired",
        description: "The uploaded document was expired. Please submit a new file with an ID that is not expired.",
        section: "owners"
    },
    {
        adyen_response_code: "1_3020",
        main_header: "Error 1_3020: Unsupported file type",
        short_text: "Unsupported file type",
        description: "The file format is not supported. Please upload a .JPG, .PNG, or .PDF",
        section: "owners"
    },
    {
        adyen_response_code: "1_3021",
        main_header: "Error 1_3021: The file uploaded could not be verified",
        short_text: "The file uploaded could not be verified",
        description: "The uploaded document was not able to be verified. Please check the file and make sure it isn't blurry, cut off, or incomplete",
        section: "owners"
    },
    {
        adyen_response_code: "1_3022",
        main_header: "Error 1_3022: The file uploaded could not be verified",
        short_text: "The file uploaded could not be verified",
        description: "The uploaded document was not able to be verified. Please check the file and make sure it isn't blurry, cut off, or incomplete",
        section: "owners"
    },
    {
        adyen_response_code: "1_3023",
        main_header: "Error 1_3023: The file uploaded could not be verified",
        short_text: "The file uploaded could not be verified",
        description: "The uploaded document was not able to be verified. Please check the file and make sure it isn't blurry, cut off, or incomplete",
        section: "owners"
    },
    {
        adyen_response_code: "1_3024",
        main_header: "Error 1_3024: The file uploaded could not be verified",
        short_text: "The file uploaded could not be verified",
        description: "The uploaded document was not able to be verified. Please check the file and make sure it isn't blurry, cut off, or incomplete",
        section: "owners"
    },
    {
        adyen_response_code: "1_3025",
        main_header: "Error 1_3025: Information mismatch",
        short_text: "Information mismatch",
        description: "The state didn't match the one on the ID document",
        section: "owners",
        fields: ["stateOrProvince"]
    },
    {
        adyen_response_code: "1_3026",
        main_header: "Error 1_3026: Information mismatch",
        short_text: "Information mismatch",
        description: "The ID document didn't show an issuing state",
        section: "owners"
    },
    {
        adyen_response_code: "1_3027",
        main_header: "Error 1_3027: The ID document wasn't valid",
        short_text: "The ID document wasn't valid",
        description: "The uploaded document was not able to be verified. Please check the file and make sure it isn't blurry, cut off, or incomplete",
        section: "owners"
    },
    {
        adyen_response_code: "1_3028",
        main_header: "Error 1_3028: Information mismatch",
        short_text: "Information mismatch",
        description: "The ID number didn't match the one on the document",
        section: "owners",
        fields: ['socialSecurityNumber']
    },
    {
        adyen_response_code: "1_3029",
        main_header: "Error 1_3029: Information mismatch",
        short_text: "Information mismatch",
        description: "The ID document didn't show a document number",
        section: "owners"
    },
    {
        adyen_response_code: "1_3030",
        main_header: "Error 1_3030: The ID document wasn't valid",
        short_text: "The ID document wasn't valid",
        description: "The uploaded document was not able to be verified. Please check the file and make sure it isn't blurry, cut off, or incomplete",
        section: "owners"
    },
    {
        adyen_response_code: "1_3031",
        main_header: "Error 1_3031: The ID document wasn't valid",
        short_text: "The ID document wasn't valid",
        description: "The uploaded document was not able to be verified. Please check the file and make sure it isn't blurry, cut off, or incomplete",
        section: "owners"
    },
    {
        adyen_response_code: "1_3032",
        main_header: "Error 1_3032: Information mismatch",
        short_text: "Information mismatch",
        description: "The first name didn't match the one on the ID document",
        section: "owners",
        fields: ["firstName"]
    },
    {
        adyen_response_code: "1_3033",
        main_header: "Error 1_3033: Information mismatch",
        short_text: "Information mismatch",
        description: "The last name didn't match the one on the ID document",
        section: "owners",
        fields: ["lastName"]
    },
    {
        adyen_response_code: "1_3034",
        main_header: "Error 1_3034: Information mismatch",
        short_text: "Information mismatch",
        description: "The date of birth didn't match the one on the ID document",
        section: "owners",
        fields: ["birthDate"]
    },
    {
        adyen_response_code: "1_3035",
        main_header: "Error 1_3035: Information mismatch",
        short_text: "Information mismatch",
        description: "The country didn't match the one on the ID document",
        section: "owners",
        fields: ["country"]
    },
    {
        adyen_response_code: "1_3036",
        main_header: "Error 1_3036: The file uploaded could not be verified",
        short_text: "The file uploaded could not be verified",
        description: "The uploaded document was not able to be verified. Please check the file and make sure it isn't blurry, cut off, or incomplete",
        section: "owners"
    },
    {
        adyen_response_code: "1_3037",
        main_header: "Error 1_3037: Information mismatch",
        short_text: "Information mismatch",
        description: "The last name didn't match the one on the proof of residential address",
        section: "owners",
        fields: ["lastName"]
    },
    {
        adyen_response_code: "1_3038",
        main_header: "Error 1_3038: Information mismatch",
        short_text: "Information mismatch",
        description: "The first name didn't match the one on the proof of residential address",
        section: "owners",
        fields: ["firstName"]
    },
    {
        adyen_response_code: "1_3039",
        main_header: "Error 1_3039: Information mismatch",
        short_text: "Information mismatch",
        description: "The date of birth didn't match the one on the proof of residential address",
        section: "owners",
        fields: ["birthDate"]
    },
    {
        adyen_response_code: "1_3040",
        main_header: "Error 1_3040: Information mismatch",
        short_text: "Information mismatch",
        description: "The proof of residential address didn't show the date it was issued",
        section: "owners"
    },
    {
        adyen_response_code: "1_3041",
        main_header: "Error 1_3041: Information mismatch",
        short_text: "Information mismatch",
        description: "The proof of residential address didn't show a name",
        section: "owners"
    },
    {
        adyen_response_code: "1_3042",
        main_header: "Error 1_3042: Information mismatch",
        short_text: "Information mismatch",
        description: "The proof of residential address didn't show an issuing country",
        section: "owners"
    },
    {
        adyen_response_code: "1_3043",
        main_header: "Error 1_3043: Unsupported file type",
        short_text: "Unsupported file type",
        description: "The file format is not supported. Please upload a .JPG, .PNG, or .PDF",
        section: "owners"
    },
    {
        adyen_response_code: "1_3044",
        main_header: "Error 1_3044: Address could not be validated",
        short_text: "Address could not be validated",
        description: "The proof of residential address was too old",
        section: "owners"
    },
    {
        adyen_response_code: "1_3045",
        main_header: "Error 1_3045: The file uploaded could not be verified",
        short_text: "The file uploaded could not be verified",
        description: "The uploaded document was not able to be verified. Please check the file and make sure it isn't blurry, cut off, or incomplete",
        section: "owners"
    },
    {
        adyen_response_code: "1_3046",
        main_header: "Error 1_3046: Address is not supported",
        short_text: "Address is not supported",
        description: "The address on the proof of residential address was a PO box",
        section: "owners",
        fields: ["address"]
    },
    {
        adyen_response_code: "1_3047",
        main_header: "Error 1_3047: Unsupported file type",
        short_text: "Unsupported file type",
        description: "The file format is not supported. Please upload a .JPG, .PNG, or .PDF",
        section: "owners"
    },
    {
        adyen_response_code: "1_3048",
        main_header: "Error 1_3048: Information mismatch",
        short_text: "Information mismatch",
        description: "The last name on the submitted filed didn't match the one submitted",
        section: "owners",
        fields: ["lastName"]
    },
    {
        adyen_response_code: "1_3049",
        main_header: "Error 1_3049: Information mismatch",
        short_text: "Information mismatch",
        description: "The first name didn't match the one on the proof of national ID number",
        section: "owners",
        fields: ["firstName"]
    },
    {
        adyen_response_code: "1_3050",
        main_header: "Error 1_3050: Information mismatch",
        short_text: "Information mismatch",
        description: "The date of birth didn't match the one on the proof of national ID number",
        section: "owners",
        fields: ["birthDate"]
    },
    {
        adyen_response_code: "1_3051",
        main_header: "Error 1_3051: The file uploaded could not be verified",
        short_text: "The file uploaded could not be verified",
        description: "The uploaded document was not able to be verified. Please check the file and make sure it isn't blurry, cut off, or incomplete",
        section: "owners"
    },
    {
        adyen_response_code: "1_3052",
        main_header: "Error 1_3052: Additional verification is needed",
        short_text: "Additional verification is needed",
        description: "An ID document is needed to verify the individual. Please submit a driver's license, passport, or other form of identification",
        section: "owners"
    },
    {
        adyen_response_code: "1_3056",
        main_header: "Error 1_3056: The file uploaded could not be verified",
        short_text: "The file uploaded could not be verified",
        description: "The uploaded document was not able to be verified. Please check the file and make sure it isn't blurry, cut off, or incomplete",
        section: "owners"
    },
    {
        adyen_response_code: "1_3057",
        main_header: "Error 1_3057: Information mismatch",
        short_text: "Information mismatch",
        description: "The name didn't match the individual tax ID",
        section: "owners",
        fields: ["firstName", "lastName"]
    },
    {
        adyen_response_code: "1_3058",
        main_header: "Error 1_3058: Information mismatch",
        short_text: "Information mismatch",
        description: "The individual tax ID was entered incorrectly",
        section: "owners",
        fields: ["socialSecurityNumber"]
    },
    {
        adyen_response_code: "1_3059",
        main_header: "Error 1_3059: Unsupported file type",
        short_text: "Unsupported file type",
        description: "The file format is not supported. Please upload a .JPG, .PNG, or .PDF",
        section: "owners"
    },
    {
        adyen_response_code: "1_3060",
        main_header: "Error 1_3060: Information mismatch",
        short_text: "Information mismatch",
        description: "Last name didn't match uploaded document. Please check personal details and resubmit. ",
        section: "owners",
        fields: ["lastName"]
    },
    {
        adyen_response_code: "1_3061",
        main_header: "Error 1_3061: Information mismatch",
        short_text: "Information mismatch",
        description: "First name didn't match uploaded document. Please check personal details and resubmit. ",
        section: "owners",
        fields: ["firstName"]
    },
    {
        adyen_response_code: "1_3062",
        main_header: "Error 1_3062: Information mismatch",
        short_text: "Information mismatch",
        description: "Identity couldn't be verified. Please check personal details and resubmit. ",
        section: "owners",
        fields: ["socialSecurityNumber"]
    },
    {
        adyen_response_code: "1_3063",
        main_header: "Error 1_3063: Information mismatch",
        short_text: "Information mismatch",
        description: "Date of birth didn't match uploaded document. Please check personal details and resubmit.",
        section: "owners",
        fields: ["birthDate"]
    },
    {
        adyen_response_code: "1_3064",
        main_header: "Error 1_3064: Some personal details could not be verified",
        short_text: "Some personal details could not be verified",
        description: "The name and individual tax ID couldn't be verified. Please check personal details and resubmit.",
        section: "owners",
        fields: ["socialSecurityNumber"]
    },
    {
        adyen_response_code: "1_3065",
        main_header: "Error 1_3065: Information mismatch",
        short_text: "Information mismatch",
        description: "Tax ID didn't match uploaded document. Please check personal details and resubmit.",
        section: "owners",
        fields: ["socialSecurityNumber"]
    },
    {
        adyen_response_code: "1_3066",
        main_header: "Error 1_3066: The file uploaded could not be verified",
        short_text: "The file uploaded could not be verified",
        description: "The uploaded document was not able to be verified. Please check the file and make sure it isn't blurry, cut off, or incomplete",
        section: "owners"
    },
    {
        adyen_response_code: "1_3067",
        main_header: "Error 1_3067: The file uploaded could not be verified",
        short_text: "The file uploaded could not be verified",
        description: "The uploaded document was not able to be verified. Please check the file and make sure it isn't blurry, cut off, or incomplete",
        section: "owners"
    },
    {
        adyen_response_code: "1_304",
        main_header: "Photo ID is missing.",
        short_text: "Upload a Photo ID",
        description: "We need a photo ID to proceed with the validation.",
        section: "owners",
        overrideAdyenText: true,
        remediatingActions: [
            {
                code: "1_304",
                message: "Upload a Photo ID",
                type: "documentUpload"
            }
        ]
    },
    {
        adyen_response_code: "1_5000",
        main_header: "Error 1_5000: Some company details could not be verified",
        short_text: "Some company details could not be verified",
        description: "The legal business name couldn't be verified",
        section: "company",
        fields: ["legalBusinessName"]
    },
    {
        adyen_response_code: "1_501",
        main_header: "Error 1_501: Registration document required",
        short_text: "Some company details could not be verified",
        description: "Please upload a registration document",
        section: "company",
        errorInfos: constitutionalDocumentFileUploadInfo,
        relatedTo: ['2_184'],
        remediatingActions: [
            {
                code: "1_501",
                message: "Upload a Business registration document",
                type: "documentUpload"
            }
        ]
    },
    {
        adyen_response_code: "1_5001",
        main_header: "Error 1_5001: Some company details could not be verified",
        short_text: "Some company details could not be verified",
        description: "The tax ID number couldn't be verified",
        section: "company"
    },
    {
        adyen_response_code: "1_5002",
        main_header: "Error 1_5002: Some company details could not be verified",
        short_text: "Some company details could not be verified",
        description: "The state couldn't be verified",
        section: "company",
        fields: ["provinceOrState"]
    },
    {
        adyen_response_code: "1_5003",
        main_header: "Error 1_5003: Some company details could not be verified",
        short_text: "Some company details could not be verified",
        description: "The legal name didn't match the one on the registry",
        section: "company",
        fields: ["legalBusinessName"]
    },
    {
        adyen_response_code: "1_5004",
        main_header: "Error 1_5004: Some company details could not be verified",
        short_text: "Some company details could not be verified",
        description: "The legal name didn't match the one on the registry",
        section: "company",
        fields: ["legalBusinessName"]
    },
    {
        adyen_response_code: "1_5005",
        main_header: "Error 1_5005: Some company details could not be verified",
        short_text: "Some company details could not be verified",
        description: "The legal name didn't match the one on the registry",
        section: "company",
        fields: ["legalBusinessName"]
    },
    {
        adyen_response_code: "1_5006",
        main_header: "Error 1_5006: Information mismatch",
        short_text: "Information mismatch",
        description: "The country didn't match the one on the registry",
        section: "company",
        fields: ["country"]
    },
    {
        adyen_response_code: "1_5007",
        main_header: "Error 1_5007: Information mismatch",
        short_text: "Information mismatch",
        description: "The registration number didn't match the one on the registry",
        section: "company",
        fields: ["employerNumber", "businessIdentificationNumber"]
    },
    {
        adyen_response_code: "1_5008",
        main_header: "Error 1_5008: Information mismatch",
        short_text: "Information mismatch",
        description: "The tax ID didn't match the one on the registry",
        section: "company",
        fields: ["employerNumber", "taxId"]
    },
    {
        adyen_response_code: "1_5009",
        main_header: "Error 1_5009: Information mismatch",
        short_text: "Information mismatch",
        description: "The registration number was provided as tax ID",
        section: "company",
        fields: ["employerNumber", "businessNumber"]
    },
    {
        adyen_response_code: "1_5010",
        main_header: "Error 1_5010: Some company details could not be verified",
        short_text: "Some company details could not be verified",
        description: "The company details couldn't be verified",
        section: "company"
    },
    {
        adyen_response_code: "1_5011",
        main_header: "Error 1_5011: Some company details could not be verified",
        short_text: "Some company details could not be verified",
        description: "Some company information could not be verified. Please check the information and resubmit your application.",
        section: "company",
        fields: ["doingBusinessAs"]
    },
    {
        adyen_response_code: "1_5012",
        main_header: "Error 1_5012: Some company details could not be verified",
        short_text: "Some company details could not be verified",
        description: "The submitted company type didn't match the one on the registry",
        section: "company",
        fields: ["businessType"]
    },
    {
        adyen_response_code: "1_5013",
        main_header: "Error 1_5013: Some company details could not be verified",
        short_text: "Some company details could not be verified",
        description: "The company was not found in the database",
        section: "company"
    },
    {
        adyen_response_code: "1_5014",
        main_header: "Error 1_5014: Some company details could not be verified",
        short_text: "Some company details could not be verified",
        description: "The registration number couldn't be verified",
        section: "company",
        fields: ["employerNumber", "businessNumber"]
    },
    {
        adyen_response_code: "1_5015",
        main_header: "Error 1_5015: Some company details could not be verified",
        short_text: "Some company details could not be verified",
        description: "The registration number didn't match the legal business name",
        section: "company",
        fields: ["employerNumber", "businessNumber"]
    },
    {
        adyen_response_code: "1_5016",
        main_header: "Error 1_5016: Some company details could not be verified",
        short_text: "Some company details could not be verified",
        description: "The tax ID number couldn't be verified",
        section: "company",
        fields: ["employerNumber", "businessNumber"],
        errorInfos: constitutionalDocumentFileUploadInfo
    },
    {
        adyen_response_code: "1_5017",
        main_header: "Error 1_5017: Some company details could not be verified",
        short_text: "Some company details could not be verified",
        description: "The tax ID number didn't match the legal business name",
        section: "company",
        fieldStep: 2,
        fields: ["employerNumber", "businessNumber", "employerIdentificationNumber"]
    },
    {
        adyen_response_code: "1_5018",
        main_header: "Error 1_5018: Some company details could not be verified",
        short_text: "Some company details could not be verified",
        description: "The business address couldn't be verified",
        section: "company",
        fields: ["address"]
    },
    {
        adyen_response_code: "1_5019",
        main_header: "Error 1_5019: Some company details could not be verified",
        short_text: "Some company details could not be verified",
        description: "The submitted company type didn't match the one on the registry",
        section: "company",
        fields: ["businessType"]
    },
    {
        adyen_response_code: "1_5020",
        main_header: "Error 1_5020: Additional verification is needed",
        short_text: "Additional verification is needed",
        description: "Please provide registration or tax documents",
        section: "company"
    },
    {
        adyen_response_code: "1_5021",
        main_header: "Error 1_5021: The file uploaded could not be verified",
        short_text: "The file uploaded could not be verified",
        description: "The uploaded document was not able to be verified. Please check the file and make sure it isn't blurry, cut off, or incomplete",
        section: "company"
    },
    {
        adyen_response_code: "1_5022",
        main_header: "Error 1_5022: Some company details could not be verified",
        short_text: "Some company details could not be verified",
        description: "The provided documentation was too old. Please provide more recent documents",
        section: "company"
    },
    {
        adyen_response_code: "1_5023",
        main_header: "Error 1_5023: Information mismatch",
        short_text: "Information mismatch",
        description: "The submitted company type didn't match the one on the registration document",
        section: "company",
        fields: ["businessType"],
        errorInfos: registrationDocumentFileUploadInfo
    },
    {
        adyen_response_code: "1_5024",
        main_header: "Error 1_5024: Insufficient information",
        short_text: "Insufficient information",
        description: "The registration document did not show the company type",
        section: "company",
        errorInfos: registrationDocumentFileUploadInfo
    },
    {
        adyen_response_code: "1_5025",
        main_header: "Error 1_5025: Address is not supported",
        short_text: "Address is not supported",
        description: "The address on the company registration document was a residential address and must be a commerical address",
        section: "company",
        fields: ["address"],
        errorInfos: registrationDocumentFileUploadInfo
    },
    {
        adyen_response_code: "1_5026",
        main_header: "Error 1_5026: Address is not supported",
        short_text: "Address is not supported",
        description: "The address on the company registration document was a PO Box and must be a commerical address",
        section: "company",
        fields: ["address"],
        errorInfos: registrationDocumentFileUploadInfo
    },
    {
        adyen_response_code: "1_5027",
        main_header: "Error 1_5027: Information mismatch",
        short_text: "Information mismatch",
        description: "The business address didn't match the one on the company registration document",
        section: "company",
        fields: ["address"],
        errorInfos: registrationDocumentFileUploadInfo
    },
    {
        adyen_response_code: "1_5028",
        main_header: "Error 1_5028: Information mismatch",
        short_text: "Information mismatch",
        description: "The registration number didn't match the one on the company registration document",
        section: "company",
        fields: ["employerNumber", "businessNumber"],
        errorInfos: registrationDocumentFileUploadInfo
    },
    {
        adyen_response_code: "1_5029",
        main_header: "Error 1_5029: Information mismatch",
        short_text: "Information mismatch",
        description: "The legal business name didn't match the one on the company registration document",
        section: "company",
        fields: ["legalBusinessName"],
        errorInfos: registrationDocumentFileUploadInfo
    },
    {
        adyen_response_code: "1_5030",
        main_header: "Error 1_5030: Information mismatch",
        short_text: "Information mismatch",
        description: "The country didn't match the one on the company registration document",
        section: "company",
        fields: ["country"],
        errorInfos: registrationDocumentFileUploadInfo
    },
    {
        adyen_response_code: "1_5031",
        main_header: "Error 1_5031: Insufficient information",
        short_text: "Insufficient information",
        description: "The company registration document didn't show a legal business name",
        section: "company",
        errorInfos: registrationDocumentFileUploadInfo
    },
    {
        adyen_response_code: "1_5032",
        main_header: "Error 1_5032: Insufficient information",
        short_text: "Insufficient information",
        description: "The company registration document didn't show a registration number",
        section: "company",
        errorInfos: registrationDocumentFileUploadInfo
    },
    {
        adyen_response_code: "1_5033",
        main_header: "Error 1_5033: Information mismatch",
        short_text: "Information mismatch",
        description: "The state didn't match the one on the company registration document",
        section: "company",
        fields: ["stateOrProvince"],
        errorInfos: registrationDocumentFileUploadInfo
    },
    {
        adyen_response_code: "1_5034",
        main_header: "Error 1_5034: Unsupported file type",
        short_text: "Unsupported file type",
        description: "The file format is not supported. Please upload a .JPG, .PNG, or .PDF",
        section: "company"
    },
    {
        adyen_response_code: "1_5035",
        main_header: "Error 1_5035: Unsupported file type",
        short_text: "Unsupported file type",
        description: "The file format is not supported. Please upload a .JPG, .PNG, or .PDF",
        section: "company"
    },
    {
        adyen_response_code: "1_5036",
        main_header: "Error 1_5036: Missing information",
        short_text: "Missing information",
        description: "Tax document is missing",
        section: "company"
    },
    {
        adyen_response_code: "1_5037",
        main_header: "Error 1_5037: Information mismatch",
        short_text: "Information mismatch",
        description: "The country didn't match the one on the tax document.",
        section: "company",
        fields: ["country"]
    },
    {
        adyen_response_code: "1_5038",
        main_header: "Error 1_5038: Unsupported file type",
        short_text: "Unsupported file type",
        description: "The file format is not supported. Please upload a .JPG, .PNG, or .PDF",
        section: "company"
    },
    {
        adyen_response_code: "1_5039",
        main_header: "Error 1_5039: The file uploaded could not be verified",
        short_text: "The file uploaded could not be verified",
        description: "The uploaded document was not able to be verified. Please check the file and make sure it isn't blurry, cut off, or incomplete",
        section: "company"
    },
    {
        adyen_response_code: "1_5040",
        main_header: "Error 1_5040: Some company details could not be verified",
        short_text: "Some company details could not be verified",
        description: "The tax document was too old. Please provide more recent documents",
        section: "company"
    },
    {
        adyen_response_code: "1_5041",
        main_header: "Error 1_5041: Information mismatch",
        short_text: "Information mismatch",
        description: "The legal name didn't match the one on the tax document",
        section: "company",
        fields: ["legalBusinessName"]
    },
    {
        adyen_response_code: "1_5042",
        main_header: "Error 1_5042: Information mismatch",
        short_text: "Information mismatch",
        description: "The tax ID number didn't match the legal business name",
        section: "company",
        fields: ["employerNumber", "businessNumber"]
    },
    {
        adyen_response_code: "1_5043",
        main_header: "Error 1_5043: Some company details could not be verified",
        short_text: "Some company details could not be verified",
        description: "The tax document didn't show a tax ID number",
        section: "company"
    },
    {
        adyen_response_code: "1_5044",
        main_header: "Error 1_5044: Some company details could not be verified",
        short_text: "Some company details could not be verified",
        description: "The tax document didn't show a legal business name",
        section: "company"
    },
    {
        adyen_response_code: "1_5045",
        main_header: "Error 1_5045: Information mismatch",
        short_text: "Information mismatch",
        description: "The state didn't match the one on the proof of address",
        section: "company",
        fields: ["stateOrProvince"]
    },
    {
        adyen_response_code: "1_5046",
        main_header: "Error 1_5046: The file uploaded could not be verified",
        short_text: "The file uploaded could not be verified",
        description: "The uploaded document was not able to be verified. Please check the file and make sure it isn't blurry, cut off, or incomplete",
        section: "company"
    },
    {
        adyen_response_code: "1_5047",
        main_header: "Error 1_5047: The file uploaded could not be verified",
        short_text: "The file uploaded could not be verified",
        description: "The uploaded document was not able to be verified. Please check the file and make sure it isn't blurry, cut off, or incomplete",
        section: "company"
    },
    {
        adyen_response_code: "1_5048",
        main_header: "Error 1_5048: Additional verification is needed",
        short_text: "Additional verification is needed",
        description: "Provide official ownership documentation",
        section: "company"
    },
    {
        adyen_response_code: "1_5049",
        main_header: "Error 1_5049: Additional verification is needed",
        short_text: "Additional verification is needed",
        description: "The affiliation document was not sufficient to prove the 100% affiliation",
        section: "company"
    },
    {
        adyen_response_code: "1_5051",
        main_header: "Error 1_5051:",
        short_text: "",
        description: "Signing person on the affiliation document could not be verified",
        section: "company"
    },
    {
        adyen_response_code: "1_5052",
        main_header: "Error 1_5052: The affiliation document verifies the payout entity is not 100% affiliated with the company. Setup can't be continued",
        short_text: "The affiliation document verifies the payout entity is not 100% affiliated with the company. Setup can't be continued",
        description: "",
        section: "company"
    },
    {
        adyen_response_code: "1_5054",
        main_header: "Error 1_5054: The file uploaded could not be verified",
        short_text: "The file uploaded could not be verified",
        description: "The uploaded document was not able to be verified. Please check the file and make sure it isn't blurry, cut off, or incomplete",
        section: "company"
    },
    {
        adyen_response_code: "1_5055",
        main_header: "Error 1_5055: The file uploaded could not be verified",
        short_text: "The file uploaded could not be verified",
        description: "The uploaded document was not able to be verified. Please check the file and make sure it isn't blurry, cut off, or incomplete",
        section: "company"
    },
    {
        adyen_response_code: "1_5056",
        main_header: "Error 1_5056: The file uploaded could not be verified",
        short_text: "The file uploaded could not be verified",
        description: "The uploaded document was not able to be verified. Please check the file and make sure it isn't blurry, cut off, or incomplete",
        section: "company"
    },
    {
        adyen_response_code: "1_5057",
        main_header: "Error 1_5057: Unsupported file type",
        short_text: "Unsupported file type",
        description: "The file format is not supported. Please upload a .JPG, .PNG, or .PDF",
        section: "company"
    },
    {
        adyen_response_code: "1_5058",
        main_header: "Error 1_5058:",
        short_text: "",
        description: "The VAT document was too old",
        section: "company"
    },
    {
        adyen_response_code: "1_5059",
        main_header: "Error 1_5059:",
        short_text: "",
        description: "The VAT document didn't show a legal name",
        section: "company"
    },
    {
        adyen_response_code: "1_5060",
        main_header: "Error 1_5060:",
        short_text: "",
        description: "The VAT document didn't show a VAT number",
        section: "company"
    },
    {
        adyen_response_code: "1_5061",
        main_header: "Error 1_5061: Information mismatch",
        short_text: "Information mismatch",
        description: "The country didn't match the one on the VAT document",
        section: "company",
        fields: ["country"]
    },
    {
        adyen_response_code: "1_5062",
        main_header: "Error 1_5062: Information mismatch",
        short_text: "Information mismatch",
        description: "The legal name didn't match the one on the VAT document",
        section: "company",
        fields: ["legalBusinessName"]
    },
    {
        adyen_response_code: "1_5063",
        main_header: "Error 1_5063:",
        short_text: "",
        description: "The organization VAT details couldn't be verified",
        section: "company",
        fields: ["employerNumber", "businessNumber"]
    },
    {
        adyen_response_code: "1_5064",
        main_header: "Error 1_5064: Information mismatch",
        short_text: "Information mismatch",
        description: "The VAT number didn't match the one on the registry",
        section: "company",
        fields: ["employerNumber", "businessNumber"]
    },
    {
        adyen_response_code: "1_5065",
        main_header: "Error 1_5065:",
        short_text: "",
        description: "The registration number was provided as VAT number",
        section: "company",
        fields: ["employerNumber", "businessNumber"]
    },
    {
        adyen_response_code: "1_5066",
        main_header: "Error 1_5066:",
        short_text: "",
        description: "The trade name (doing business as) was entered instead of the legal name",
        section: "company",
        fields: ["legalBusinessName"]
    },
    {
        adyen_response_code: "1_5067",
        main_header: "Error 1_5067: Information mismatch",
        short_text: "Information mismatch",
        description: "The legal name didn't match the one on the VAT registry",
        section: "company",
        fields: ["legalBusinessName"]
    },
    {
        adyen_response_code: "1_6000",
        main_header: "Error 1_6000: The sole proprietorship's name couldn't be verified.",
        short_text: "Information mismatch",
        description: "The legal name needs to be updated",
        section: "company",
        fields: ["legalName"]
    },
    {
        adyen_response_code: "1_6001",
        main_header: "Error 1_6001: The sole proprietorship's name didn't match the registration number.",
        short_text: "Information mismatch",
        description: "The legal name needs to be updated",
        section: "company",
        fields: ["legalName"]
    },
    {
        adyen_response_code: "1_6002",
        main_header: "Error 1_6002: The sole proprietorship's registration number couldn't be verified.",
        short_text: "Information mismatch",
        description: "The registration number needs to be updated",
        section: "company",
        fields: ["employerNumber"]
    },
    {
        adyen_response_code: "1_6003",
        main_header: "Error 1_6003: The provided information is not associated with a sole proprietorship.",
        short_text: "Information mismatch",
        description: "Update the information with the correct sole proprietorship information.",
        section: "company",
        fields: []
    },
    {
        adyen_response_code: "1_6004",
        main_header: "Error 1_6004: The country of governing law couldn't be verified.",
        short_text: "Information mismatch",
        description: "Update the country information",
        section: "company",
        fields: ["country"]
    },
    {
        adyen_response_code: "1_6005",
        main_header: "Error 1_6005: Constitutional document is missing.",
        short_text: "Information missing",
        description: "Upload a constitutional document",
        section: "company",
        fields: []
    },
    {
        adyen_response_code: "1_6006",
        main_header: "Error 1_6006: The constitutional document was too old.",
        short_text: "Old information",
        description: "Upload a different constitutional document",
        section: "company",
        fields: []
    },
    {
        adyen_response_code: "1_6007",
        main_header: "Error 1_6007: The sole proprietorship's name didn't match the one on the constitutional document.",
        short_text: "Information mismatch",
        description: "Update sole proprietorship details or Upload a constitutional document",
        section: "company",
        fields: ["legalName"]
    },
    {
        adyen_response_code: "1_6008",
        main_header: "Error 1_6008: Country of governing law didn't match the constitutional document.",
        short_text: "Information mismatch",
        description: "Update sole proprietorship details or Upload a constitutional document",
        section: "company",
        fields: ["country"]
    },
    {
        adyen_response_code: "1_6009",
        main_header: "Error 1_6009: The constitutional document didn't show the sole proprietorship's name.",
        short_text: "Information mismatch",
        description: "Update sole proprietorship details or Upload a constitutional document",
        section: "company",
        fields: ["legalName"]
    },
    {
        adyen_response_code: "1_6010",
        main_header: "Error 1_6010: The constitutional document didn't show a registration number.",
        short_text: "Information mismatch",
        description: "Update sole proprietorship details or Upload a constitutional document",
        section: "company",
        fields: ["employerNumber"]
    },
    {
        adyen_response_code: "1_6011",
        main_header: "Error 1_6011: The constitutional document didn't show a sole proprietorship.",
        short_text: "Information mismatch",
        description: "Update sole proprietorship details or Upload a constitutional document",
        section: "company",
        fields: []
    },
    {
        adyen_response_code: "1_6012",
        main_header: "Error 1_6012: The sole proprietorship's state didn't match the one on the constitutional document.",
        short_text: "Information mismatch",
        description: "Update sole proprietorship details or Upload a constitutional document",
        section: "company",
        fields: ["stateOrProvince"]
    },
    {
        adyen_response_code: "1_6013",
        main_header: "Error 1_6013: The registered state couldn't be verified.",
        short_text: "Information mismatch",
        description: "Update sole proprietorship details or Upload a constitutional document",
        section: "company",
        fields: ["stateOrProvince"]
    },
    {
        adyen_response_code: "1_6014",
        main_header: "Error 1_6014: The submitted registered state didn't match the one on the registry.",
        short_text: "Information mismatch",
        description: "Update sole proprietorship details or Upload a constitutional document",
        section: "company",
        fields: ["stateOrProvince"]
    },
    {
        adyen_response_code: "1_6015",
        main_header: "Error 1_6015: The constitutional document contained sensitive data.",
        short_text: "Information mismatch",
        description: "Upload a different constitutional document",
        section: "company",
        fields: []
    },
    {
        adyen_response_code: "1_6016",
        main_header: "Error 1_6016: The constitutional document is not supported.",
        short_text: "Information mismatch",
        description: "Upload a different constitutional document",
        section: "company",
        fields: []
    },
    {
        adyen_response_code: "1_6017",
        main_header: "Error 1_6017: The constitutional document doesn't belong to the sole proprietorship.",
        short_text: "Information mismatch",
        description: "Upload a different constitutional document",
        section: "company",
        fields: []
    },
    {
        adyen_response_code: "1_6018",
        main_header: "Error 1_6018: The sole proprietorship was not found in the database.",
        short_text: "Information mismatch",
        description: "Update sole proprietorship details",
        section: "company",
        fields: []
    },
    {
        adyen_response_code: "1_6019",
        main_header: "Error 1_6019: The constitutional document didn't show the date of issue.",
        short_text: "Information mismatch",
        description: "Upload a different constitutional document",
        section: "company",
        fields: []
    },
    {
        adyen_response_code: "1_6019",
        main_header: "Error 1_6019: The constitutional document didn't show the date of issue.",
        short_text: "Information mismatch",
        description: "Upload a different constitutional document",
        section: "company",
        fields: []
    },
    {
        adyen_response_code: "1_6020",
        main_header: "Error 1_6020: The constitutional document didn't show a stamp/signature.",
        short_text: "Information mismatch",
        description: "Upload a different constitutional document",
        section: "company",
        fields: []
    },
    {
        adyen_response_code: "1_6021",
        main_header: "Error 1_6021: The image quality of the constitutional document was too low.",
        short_text: "Information mismatch",
        description: "Upload a different constitutional document",
        section: "company",
        fields: []
    },
    {
        adyen_response_code: "1_6022",
        main_header: "Error 1_6022: The sole proprietorship registration number didn't match the one on the registry.",
        short_text: "Information mismatch",
        description: "Update sole proprietorship details",
        section: "company",
        fields: ["employerNumber"]
    },
    {
        adyen_response_code: "1_6023",
        main_header: "Error 1_6023: The sole proprietorship name didn't match the one on the registry.",
        short_text: "Information mismatch",
        description: "Update sole proprietorship details",
        section: "company",
        fields: ["legalName"]
    },
    {
        adyen_response_code: "1_6024",
        main_header: "Error 1_6024: The trade name (doing business as) was entered instead of the sole proprietorship name.",
        short_text: "Information mismatch",
        description: "Update sole proprietorship details",
        section: "company",
        fields: ["doingBusinessAs"]
    },
    {
        adyen_response_code: "1_6025",
        main_header: "Error 1_6025: The sole proprietorship name is incorrect.",
        short_text: "Information mismatch",
        description: "Update sole proprietorship details",
        section: "company",
        fields: ["legalName"]
    },
    {
        adyen_response_code: "1_6026",
        main_header: "Error 1_6026: The sole proprietorship registration number did not match the one on the constitutional document.",
        short_text: "Information mismatch",
        description: "Upload a different constitutional document",
        section: "company",
        fields: ["employerNumber"]
    },
    {
        adyen_response_code: "1_6027",
        main_header: "Error 1_6027: The sole proprietorship is registered as a different entity type",
        short_text: "Information mismatch",
        description: "Update sole proprietorship details",
        section: "company",
        fields: []
    },
    {
        adyen_response_code: "1_6028",
        main_header: "Error 1_6028: The constitutional document is not associated with a sole proprietorship.",
        short_text: "Information mismatch",
        description: "Upload a different constitutional document",
        section: "company",
        fields: []
    },
    {
        adyen_response_code: "2_184",
        main_header: "Error 2_184: The Registration document to perform verification was missing.",
        short_text: "Upload a Registration document",
        description: "Upload a Registration document",
        section: "company",
        fields: [],
        overrideAdyenText: true,
        errorInfos: constitutionalDocumentFileUploadInfo,
        relatedTo: ['1_501']
    },
    {
        adyen_response_code: "1_7000",
        main_header: "Error 1_7000:",
        short_text: "",
        description: "The bank account couldn't be verified",
        section: "bankAccount",
        fields: ["accountNumber"],
        errorInfos: bankAccountFileUploadInfo
    },
    {
        adyen_response_code: "1_7001",
        main_header: "Error 1_7001:",
        short_text: "",
        description: "The routing number wasn't valid",
        section: "bankAccount",
        fields: ["branchCode", "transitNumber", "institutionNumber"],
        errorInfos: bankAccountFileUploadInfo
    },
    {
        adyen_response_code: "1_7002",
        main_header: "Error 1_7002:",
        short_text: "",
        description: "The bank account number wasn't valid",
        section: "bankAccount",
        fields: ["accountNumber"],
        errorInfos: bankAccountFileUploadInfo
    },
    {
        adyen_response_code: "1_703",
        main_header: "Error 1_703: A bank statement is required",
        short_text: "Upload a bank statement",
        description: "Please upload a bank statement",
        section: "bankAccount",
        fields: ["accountNumber"],
        errorInfos: bankAccountFileUploadInfo
    },
    {
        adyen_response_code: "1_7003",
        main_header: "Error 1_7003:",
        short_text: "",
        description: "The bank account couldn't be verified",
        section: "bankAccount",
        fields: ["accountNumber"],
        errorInfos: bankAccountFileUploadInfo
    },
    {
        adyen_response_code: "1_7004",
        main_header: "Error 1_7004:",
        short_text: "",
        description: "The bank account entered was invalid",
        section: "bankAccount",
        errorInfos: bankAccountFileUploadInfo
    },
    {
        adyen_response_code: "1_7007",
        main_header: "Error 1_7007:",
        short_text: "",
        description: "The verification code wasn't entered",
        section: "bankAccount",
        errorInfos: bankAccountFileUploadInfo
    },
    {
        adyen_response_code: "1_7008",
        main_header: "Error 1_7008:",
        short_text: "",
        description: "There is no bank document to verify",
        section: "bankAccount",
        errorInfos: bankAccountFileUploadInfo
    },
    {
        adyen_response_code: "1_7009",
        main_header: "Error 1_7009:",
        short_text: "",
        description: "The bank document didn't show a bank account number",
        section: "bankAccount",
        errorInfos: bankAccountFileUploadInfo
    },
    {
        adyen_response_code: "1_7010",
        main_header: "Error 1_7010:",
        short_text: "",
        description: "The bank document didn't show the account owner name",
        section: "bankAccount",
        errorInfos: bankAccountFileUploadInfo
    },
    {
        adyen_response_code: "1_7011",
        main_header: "Error 1_7011: Information mismatch",
        short_text: "Information mismatch",
        description: "The bank account number didn't match the one on the bank document",
        section: "bankAccount",
        fields: ["accountNumber"],
        errorInfos: bankAccountFileUploadInfo
    },
    {
        adyen_response_code: "1_7012",
        main_header: "Error 1_7012: Information mismatch",
        short_text: "Information mismatch",
        description: "The bank account owner name appearing in the document does not match the verified legal name",
        section: "bankAccount",
        fields: ["holderName"],
        errorInfos: bankAccountFileUploadInfo
    },
    {
        adyen_response_code: "1_7013",
        main_header: "Error 1_7013: The file uploaded could not be verified",
        short_text: "The file uploaded could not be verified",
        description: "The uploaded document was not able to be verified. Please check the file and make sure it isn't blurry, cut off, or incomplete",
        section: "bankAccount",
        errorInfos: bankAccountFileUploadInfo
    },
    {
        adyen_response_code: "1_7014",
        main_header: "Error 1_7014: Unsupported file type",
        short_text: "Unsupported file type",
        description: "The file format is not supported. Please upload a .JPG, .PNG, or .PDF",
        section: "bankAccount",
        errorInfos: bankAccountFileUploadInfo
    },
    {
        adyen_response_code: "1_7015",
        main_header: "Error 1_7015: Information mismatch",
        short_text: "Information mismatch",
        description: "The bank account country didn't match the one on the bank document",
        section: "bankAccount",
        errorInfos: bankAccountFileUploadInfo
    },
    {
        adyen_response_code: "1_7016",
        main_header: "Error 1_7016: Information mismatch",
        short_text: "Information mismatch",
        description: "The branch code didn't match the one on the bank document",
        section: "bankAccount",
        fields: ["branchCode", "transitNumber", "institutionNumber"],
        errorInfos: bankAccountFileUploadInfo
    },
    {
        adyen_response_code: "1_7017",
        main_header: "Error 1_7017: Information mismatch",
        short_text: "Information mismatch",
        description: "The bank account currency didn't match the one on the bank document",
        section: "bankAccount",
        errorInfos: bankAccountFileUploadInfo
    },
    {
        adyen_response_code: "1_7018",
        main_header: "Error 1_7018:",
        short_text: "",
        description: "The bank document wasn't officially issued by the bank",
        section: "bankAccount",
        errorInfos: bankAccountFileUploadInfo
    },
    {
        adyen_response_code: "1_7019",
        main_header: "Error 1_7019:",
        short_text: "",
        description: "The bank document wasn't issued in the past 12 months",
        section: "bankAccount",
        errorInfos: bankAccountFileUploadInfo
    },
    {
        adyen_response_code: "1_7020",
        main_header: "Error 1_7020: Information mismatch",
        short_text: "Information mismatch",
        description: "The bank name didn't match the one on the bank document",
        section: "bankAccount",
        fields: ["bankName"],
        errorInfos: bankAccountFileUploadInfo
    },
    {
        adyen_response_code: "1_7021",
        main_header: "Error 1_7021:",
        short_text: "",
        description: "The bank document didn't show an official bank logo",
        section: "bankAccount",
        errorInfos: bankAccountFileUploadInfo
    },
    {
        adyen_response_code: "1_7022",
        main_header: "Error 1_7022:",
        short_text: "",
        description: "The bank document didn't show an official bank stamp",
        section: "bankAccount",
        errorInfos: bankAccountFileUploadInfo
    },
    {
        adyen_response_code: "2_8009",
        main_header: "Error 2_8009: First name for the individual was missing",
        short_text: "",
        description: "Add the first name for the manager/owner",
        fields: ['firstName'],
        section: "owners"
    },
    {
        adyen_response_code: "2_8011",
        main_header: "Error 2_8011: Last name for the individual was missing",
        short_text: "",
        description: "Add the last name for the manager/owner",
        fields: ['lastName'],
        section: "owners"
    },
    {
        adyen_response_code: "2_8017",
        main_header: "Error 2_8017: The country for the individual residential address was missing",
        short_text: "",
        description: "Add the country for the individual residential address",
        parentFieldRef: "address",
        fields: ['country'],
        section: "owners"
    },
    {
        adyen_response_code: "2_8018",
        main_header: "Error 2_8018: The state or province for the individual residential address was missing",
        short_text: "",
        description: "Add the state or province for the individual residential address",
        parentFieldRef: "address",
        fields: ['stateOrProvince'],
        section: "owners"
    },
    {
        adyen_response_code: "2_8019",
        main_header: "Error 2_8019: The street for the individual residential address was missing",
        short_text: "",
        description: "Add the street for the individual residential address",
        parentFieldRef: "address",
        fields: ['street1'],
        section: "owners"
    },
    {
        adyen_response_code: "2_8020",
        main_header: "Error 2_8020: The city for the individual residential address was missing",
        short_text: "",
        description: "Add the city for the individual residential address",
        parentFieldRef: "address",
        fields: ['city'],
        section: "owners"
    },
    {
        adyen_response_code: "2_8021",
        main_header: "Error 2_8021: The postalCode/zipCode for the individual residential address was missing",
        short_text: "",
        description: "Add the postalCode/zipCode for the individual residential address",
        parentFieldRef: "address",
        fields: ['postalCode', 'zipCode'],
        section: "owners"
    },
    {
        adyen_response_code: "2_8022",
        main_header: "Error 2_8022: The 2nd street field for the individual residential address was missing",
        short_text: "",
        description: "Add the 2nd street field for the individual residential address",
        parentFieldRef: "address",
        fields: ['street2'],
        section: "owners"
    },
    {
        adyen_response_code: "2_8025",
        main_header: "Error 2_8025: The individual nationality was missing",
        short_text: "",
        description: "Add the individual nationality",
        parentFieldRef: "address",
        fields: ['country'],
        section: "owners"
    },
    {
        adyen_response_code: "2_8028",
        main_header: "Error 2_8028: The individual date of birth was missing",
        short_text: "",
        description: "Add the individual date of birth",
        fields: ['birthDate'],
        section: "owners"
    },
    {
        adyen_response_code: "2_8030",
        main_header: "Error 2_8030: The iban field for the bank account was missing",
        short_text: "",
        description: "Add the iban field for the bank account",
        section: "bankAccount",
        errorInfos: bankAccountFileUploadInfo
    },
    {
        adyen_response_code: "2_8032",
        main_header: "Error 2_8032: The account number for the bank account was missing",
        short_text: "",
        description: "Add the account number for the bank account",
        fields: ['accountNumber'],
        section: "bankAccount",
        errorInfos: bankAccountFileUploadInfo
    },
    {
        adyen_response_code: "2_8036",
        main_header: "Error 2_8036: The bank account was missing",
        short_text: "",
        description: "Add the bank account",
        section: "bankAccount",
        errorInfos: bankAccountFileUploadInfo
    },
    {
        adyen_response_code: "2_8037",
        main_header: "Error 2_8037: The bank statement was missing",
        short_text: "",
        description: "Add the bank statement",
        section: "bankAccount",
        errorInfos: bankAccountFileUploadInfo
    },
    {
        adyen_response_code: "2_8038",
        main_header: "Error 2_8038: The branch code for the bank account was missing",
        short_text: "",
        description: "Add the branch code for the bank account",
        fields: ['branchCode'],
        section: "bankAccount",
        errorInfos: bankAccountFileUploadInfo
    },
    {
        adyen_response_code: "2_8043",
        main_header: "Error 2_8043: The registration number for the organization was missing",
        short_text: "",
        description: "Add the registration number for the organization",
        fields: ['registrationNumber'],
        section: "company"
    },
    {
        adyen_response_code: "2_8044",
        main_header: "Error 2_8044: The registration number for the sole proprietorship was missing",
        short_text: "",
        description: "Add the registration number for the sole proprietorship",
        fields: ['registrationNumber'],
        section: "company"
    },
    {
        adyen_response_code: "2_8045",
        main_header: "Error 2_8045: The tax id for the organization was missing",
        short_text: "",
        description: "Add the tax id for the organization",
        fields: ['taxId'],
        section: "company"
    },
    {
        adyen_response_code: "2_8052",
        main_header: "Error 2_8052: The tax id for the organization was missing",
        short_text: "",
        fields: ['taxId'],
        description: "Add the tax id for the organization",
        section: "company"
    },
    {
        adyen_response_code: "2_8058",
        main_header: "Error 2_8058: The name for the individual was missing",
        short_text: "",
        fields: ['firstName', 'lastName'],
        description: "Add the name for the individual",
        section: "owners"
    },
    {
        adyen_response_code: "2_8059",
        main_header: "Error 2_8059: The birth date for the individual was missing",
        short_text: "",
        fields: ['birthDate'],
        description: "Add the birth data for the individual",
        section: "owners"
    },
    {
        adyen_response_code: "2_8060",
        main_header: "Error 2_8060: The identification data for the individual was missing",
        short_text: "",
        fields: ['ssn'],
        description: "Add the identification data (SSN) for the individual",
        section: "owners"
    },
    {
        adyen_response_code: "2_8062",
        main_header: "Error 2_8062: The residential address for the individual was missing",
        short_text: "",
        fields: ['address'],
        description: "Add the individual's address",
        section: "owners"
    },
    {
        adyen_response_code: "2_8064",
        main_header: "Error 2_8064: The UBO through ownership of the organization was missing",
        short_text: "",
        description: "Contact support: Empty UBO through ownership for the organization",
        section: "organization"
    },
    {
        adyen_response_code: "2_8067",
        main_header: "Error 2_8067: The signatory for the organization is required",
        short_text: "",
        description: "Contact support: Add the signatory for the organization",
        section: "company"
    },
    {
        adyen_response_code: "2_8069",
        main_header: "Error 2_8069: The organization type was missing",
        short_text: "",
        description: "Add the organization type (First step)",
        section: "company"
    },
    {
        adyen_response_code: "2_8071",
        main_header: "Error 2_8071: The identity document was missing",
        short_text: "",
        description: "Add the identity document",
        section: "company"
    },
    {
        adyen_response_code: "2_901",
        main_header: "Error 2_901: PCI forms are not signed",
        short_text: "",
        description: "Sign PCI",
        section: "bankAccount",
        errorInfos: bankAccountFileUploadInfo
    },
    {
        adyen_response_code: "2_8076",
        main_header: "Error 2_8076: The drivers licence document was missing",
        short_text: "",
        description: "Upload the drivers licence",
        section: "owners"
    },
    {
        adyen_response_code: "2_8077",
        main_header: "Error 2_8077: The front image of drivers licence document was missing",
        short_text: "",
        description: "Upload the front image of drivers licence document",
        section: "owners"
    },
    {
        adyen_response_code: "2_8078",
        main_header: "Error 2_8078: The back image of drivers licence document was missing",
        short_text: "",
        description: "Upload the back image of drivers licence document",
        section: "owners"
    },
    {
        adyen_response_code: "2_8079",
        main_header: "Error 2_8079: The identity card was missing",
        short_text: "",
        description: "Upload the identity card document",
        section: "owners"
    },
    {
        adyen_response_code: "2_8080",
        main_header: "Error 2_8080: The back image of identity card was missing",
        short_text: "",
        description: "Upload the back image of identity card",
        section: "owners"
    },
    {
        adyen_response_code: "2_8081",
        main_header: "Error 2_8081: The front image of identity card was missing",
        short_text: "",
        description: "Upload front image of identity card",
        section: "owners"
    },
    {
        adyen_response_code: "2_8082",
        main_header: "Error 2_8082: The individual identification data number was missing",
        short_text: "",
        description: "Add the individual identification data number",
        section: "owners"
    },
    {
        adyen_response_code: "2_8085",
        main_header: "Error 2_8085: The legal name of organization was missing",
        short_text: "",
        description: "Add the legal name of organization",
        fields: ['legalName'],
        section: "company"
    },
    {
        adyen_response_code: "2_8086",
        main_header: "Error 2_8086: The doing business as was missing",
        short_text: "",
        description: "Add the doing business as (DBA) of organization",
        fields: ['dba', 'doingBusinessAs'],
        section: "company"
    },
    {
        adyen_response_code: "2_8100",
        main_header: "Error 2_8100: The VAT document was missing",
        short_text: "",
        description: "Upload the VAT document",
        section: "company"
    },
    {
        adyen_response_code: "2_8107",
        main_header: "Error 2_8107: The proof of residency document was missing",
        short_text: "",
        description: "Upload the proof of residency document",
        section: "company"
    },
    {
        adyen_response_code: "2_8108",
        main_header: "Error 2_8108: The continuous payout entity was missing",
        short_text: "",
        description: "Contact support: No remediation possible",
        section: "company"
    },
    {
        adyen_response_code: "2_8109",
        main_header: "Error 2_8109: The full affiliation proof was missing",
        short_text: "",
        description: "Contact support: No remediation possible",
        section: "company"
    },
    {
        adyen_response_code: "2_8109",
        main_header: "Error 2_8109: The full affiliation proof was missing",
        short_text: "",
        description: "Contact support: No remediation possible",
        section: "company"
    },
    {
        adyen_response_code: "2_8129",
        main_header: "Error 2_8129: The name was missing",
        short_text: "",
        description: "Contact support: No remediation possible",
        section: "company"
    },
    {
        adyen_response_code: "2_8131",
        main_header: "Error 2_8131: The type was missing",
        short_text: "",
        description: "Contact support: No remediation possible",
        section: "company"
    },
    {
        adyen_response_code: "2_8138",
        main_header: "Error 2_8138: The passport was missing",
        short_text: "",
        description: "Contact support: No remediation possible",
        section: "company"
    },
    {
        adyen_response_code: "2_8139",
        main_header: "Error 2_8139: The proof of national id was missing",
        short_text: "",
        description: "Upload a proof of national id",
        section: "owners"
    },
    {
        adyen_response_code: "2_8140",
        main_header: "Error 2_8140: The proof of address was missing",
        short_text: "",
        description: "Upload a proof of address document",
        section: "owners"
    },
    {
        adyen_response_code: "2_8141",
        main_header: "Error 2_8141: The registration document was missing",
        short_text: "",
        description: "Upload a registration document",
        section: "owners",
        errorInfos: registrationDocumentFileUploadInfo
    },
    {
        adyen_response_code: "2_8148",
        main_header: "Error 2_8148: The individual identification data type was missing",
        short_text: "",
        description: "Add the individual data type to the legal entity (Contact support)",
        section: "owners"
    },
    {
        adyen_response_code: "2_8149",
        main_header: "Error 2_8149: The individual email was missing",
        short_text: "",
        description: "Add the individual email",
        fields: ['email'],
        section: "owners"
    },
    {
        adyen_response_code: "2_8150",
        main_header: "Error 2_8150: The individual phone number was missing",
        short_text: "",
        description: "Add the individual phone number to legal entity",
        section: "owners"
    },
    {
        adyen_response_code: "2_8151",
        main_header: "Error 2_8151: The individual phone type was missing",
        short_text: "",
        description: "Add the individual phone type to legal entity",
        section: "owners"
    },
    {
        adyen_response_code: "2_8152",
        main_header: "Error 2_8152: The individual phone country was missing",
        short_text: "",
        description: "Add the individual phone country to legal entity",
        section: "owners"
    },
    {
        adyen_response_code: "2_8153",
        main_header: "Error 2_8153: The individual job title was missing",
        short_text: "",
        description: "Add the individual job title to legal entity",
        fields: ['jobTitle'],
        section: "company"
    },
    {
        adyen_response_code: "2_8154",
        main_header: "Error 2_8154: The bank account type was missing",
        short_text: "",
        description: "Add the type of bank account",
        fields: ['jobTitle'],
        section: "bankAccount",
        errorInfos: bankAccountFileUploadInfo
    },
    {
        adyen_response_code: "2_8155",
        main_header: "Error 2_8155: The bank account bic swift was missing",
        short_text: "",
        description: "Add the bic swift of bank account",
        section: "bankAccount",
        errorInfos: bankAccountFileUploadInfo
    },
    {
        adyen_response_code: "2_8156",
        main_header: "Error 2_8156: The bank account city was missing",
        short_text: "",
        description: "Add the city of bank account",
        section: "bankAccount",
        errorInfos: bankAccountFileUploadInfo
    },
    {
        adyen_response_code: "2_8157",
        main_header: "Error 2_8157: The bank account code was missing",
        short_text: "",
        description: "Add the code of bank account",
        section: "bankAccount",
        errorInfos: bankAccountFileUploadInfo
    },
    {
        adyen_response_code: "2_8158",
        main_header: "Error 2_8158: The bank account name was missing",
        short_text: "",
        description: "Add the name of bank account",
        section: "bankAccount",
        errorInfos: bankAccountFileUploadInfo
    },
    {
        adyen_response_code: "2_8159",
        main_header: "Error 2_8159: The bank account check code was missing",
        short_text: "",
        description: "Add the check code of bank account",
        section: "bankAccount",
        errorInfos: bankAccountFileUploadInfo
    },
    {
        adyen_response_code: "2_8161",
        main_header: "Error 2_8161: The bank account currency code was missing",
        short_text: "",
        description: "Add the currency code of bank account",
        section: "bankAccount",
        errorInfos: bankAccountFileUploadInfo
    },
    {
        adyen_response_code: "2_8162",
        main_header: "Error 2_8162: The proof of organization info was missing",
        short_text: "",
        description: "Upload the proof of organization info",
        section: "company"
    },
    {
        adyen_response_code: "2_8163",
        main_header: "Error 2_8163: The source of funds was missing",
        short_text: "",
        description: "Add the source of funds to business line",
        section: "company"
    },
    {
        adyen_response_code: "2_8164",
        main_header: "Error 2_8164: The source of funds type was missing",
        short_text: "",
        description: "Add the source of funds type to business line",
        section: "company"
    },
    {
        adyen_response_code: "2_8168",
        main_header: "Error 2_8168: The source of funds adyenProcessedFunds field was missing",
        short_text: "",
        description: "Add the source of funds adyenProcessedFunds field",
        section: "company"
    },
    {
        adyen_response_code: "2_8170",
        main_header: "Error 2_8170: The source of funds description field was missing",
        short_text: "",
        description: "Add the source of funds description field",
        section: "company"
    },
    {
        adyen_response_code: "2_8170",
        main_header: "Error 2_8170: The source of funds description field was missing",
        short_text: "",
        description: "Add the source of funds description field",
        section: "company"
    },
    {
        adyen_response_code: "2_8170",
        main_header: "Error 2_8170: The tax information of organization was missing",
        short_text: "",
        description: "Add the tax information of organization",
        section: "company"
    },
    {
        adyen_response_code: "2_8175",
        main_header: "Error 2_8175: The tax information number of organization was missing",
        short_text: "",
        description: "Add the tax information number of organization",
        section: "company"
    },
    {
        adyen_response_code: "2_8176",
        main_header: "Error 2_8176: The tax information type of organization was missing",
        short_text: "",
        description: "Add the tax information type of organization",
        section: "company"
    },
    {
        adyen_response_code: "2_8177",
        main_header: "Error 2_8177: The tax information country of organization was missing",
        short_text: "",
        description: "Add the tax information country of organization",
        section: "company"
    },
    {
        adyen_response_code: "2_8178",
        main_header: "Error 2_8178: The proof of individual tax id was missing",
        short_text: "",
        description: "Add the tax information of organization",
        section: "company"
    },
    {
        adyen_response_code: "2_8179",
        main_header: "Error 2_8179: The VAT number was missing",
        short_text: "",
        description: "Add the tax information of organization",
        section: "company"
    },
    {
        adyen_response_code: "2_8180",
        main_header: "Error 2_8180: The VAT document was missing",
        short_text: "",
        description: "Add the vat document of legal entity",
        section: "company"
    },
    {
        adyen_response_code: "2_8181",
        main_header: "Error 2_8181: The industry code was missing",
        short_text: "",
        description: "Add the industry code of business line",
        section: "company"
    },
    {
        adyen_response_code: "2_8182",
        main_header: "Error 2_8182: The channel of business line was missing",
        short_text: "",
        description: "Add the channel of business line",
        section: "company"
    },
    {
        adyen_response_code: "2_8183",
        main_header: "Error 2_8183: The web address of business line was missing",
        short_text: "",
        description: "Add the web address of business line",
        section: "company"
    },
    {
        adyen_response_code: "2_8184",
        main_header: "Error 2_8184: The tax reporting classification was missing",
        short_text: "",
        description: "Add the tax reporting classification to legal entity",
        section: "company"
    },
    {
        adyen_response_code: "2_8185",
        main_header: "Error 2_8185: The tax reporting classification type was missing",
        short_text: "",
        description: "Add the tax reporting classification type to legal entity",
        section: "company"
    },
    {
        adyen_response_code: "2_8186",
        main_header: "Error 2_8186: The tax reporting classification business type was missing",
        short_text: "",
        description: "Add the tax reporting classification business type to legal entity",
        section: "company"
    },
    {
        adyen_response_code: "2_8187",
        main_header: "Error 2_8187: The tax reporting classification main source of income was missing",
        short_text: "",
        description: "Add the tax reporting classification business main source of income to legal entity",
        section: "company"
    },
    {
        adyen_response_code: "2_8188",
        main_header: "Error 2_8188: The tax reporting classification financial institution number was missing",
        short_text: "",
        description: "Add the tax reporting classification business financial institution number to legal entity",
        section: "company"
    },
    {
        adyen_response_code: "2_8189",
        main_header: "Error 2_8189: The UBO through control was missing",
        short_text: "",
        description: "Add the UBO through control to legal entity",
        section: "company"
    },
    {
        adyen_response_code: "2_8190",
        main_header: "Error 2_8190: The business line was missing",
        short_text: "",
        description: "Add the business line legal entity",
        section: "company"
    },
    {
        adyen_response_code: "2_8191",
        main_header: "Error 2_8191: The business line was missing",
        short_text: "",
        description: "Add the business line legal entity",
        section: "company"
    },
    {
        adyen_response_code: "2_8192",
        main_header: "Error 2_8192: The constitutional document was missing",
        short_text: "",
        description: "Add the constitutional document to legal entity",
        section: "company"
    },
    {
        adyen_response_code: "2_901",
        main_header: "Error 2_901: The PCI forms are not signed",
        short_text: "",
        description: "Sign PCI",
        section: "company"
    },
    {
        adyen_response_code: "2_902",
        main_header: "Error 2_902: The terms of service forms are not accepted",
        short_text: "",
        description: "Accept TOS",
        section: "company"
    }
];

export const invalidFieldErrorCodes = [
    {
        adyen_response_code: "organization.registeredAddress.postalCode",
        main_header: "Invalid legal entity information provided",
        description: "Postal code not valid for US. Allowed formats: NNNNN or NNNNN-NNNN",
        section: "company",
        fields: ["postalCode"],
        fieldStep: 2,
        parentFieldRef: "companyAddressRef",
        remediatingActions: [
            {
                message: "Change the company postal code.",
                type: "dataChange"
            }
        ]
    },
    {
        adyen_response_code: "individual.residentialAddress.postalCode",
        main_header: "Invalid legal entity information provided",
        description: "Postal code not valid for US. Allowed formats: NNNNN or NNNNN-NNNN",
        section: "owners",
        fields: ["postalCode"],
        parentFieldRef: "address",
        remediatingActions: [
            {
                message: "Change the owner postal code.",
                type: "dataChange"
            }
        ]
    },
    {
        adyen_response_code: "Invalid SSN number. Number should be 9 digits",
        main_header: "Invalid legal entity information provided",
        description: "Invalid SSN number. Number should be 9 digits",
        section: "owners",
        fields: ["socialSecurityNumber"],
        remediatingActions: [
            {
                message: "Change owner's Security Social Number.",
                type: "dataChange"
            }
        ]
    },
    {
        adyen_response_code: "individual.residentialAddress.stateOrProvince",
        main_header: "Invalid individual information provided",
        description: "A required value was not specified: empty value",
        section: "owners",
        fields: ["stateOrProvince"],
        parentFieldRef: "address",
        remediatingActions: [
            {
                message: "Change owner's State or Province.",
                type: "dataChange"
            }
        ]
    },
    {
        adyen_response_code: "individual.residentialAddress.city",
        main_header: "Invalid individual information provided",
        description: "A required value was not specified: empty value",
        section: "owners",
        fields: ["city"],
        parentFieldRef: "address",
        remediatingActions: [
            {
                message: "Change owner's City.",
                type: "dataChange"
            }
        ]
    },
    {
        adyen_response_code: "organization.registrationNumber",
        main_header: "Invalid legal entity information provided",
        description: "Not valid for US. Format should be: 9 digits. For example: 101002749.",
        section: "company",
        fields: ["employerIdentificationNumber"],
        fieldStep: 2,
        remediatingActions: [
            {
                message: "Change company's Employer Identification Number.",
                type: "dataChange"
            }
        ]
    },
    {
        adyen_response_code: "bankAccount.branchCode",
        main_header: "Invalid legal entity information provided",
        description: "unknown ABA/routing transit number (wire transfer)",
        section: "bankAccount",
        fields: ["branchCode", "transitNumber"],
        remediatingActions: [
            {
                message: "Change bank account Routing/Transit Number.",
                type: "dataChange"
            }
        ],
        errorInfos: bankAccountFileUploadInfo
    },
    {
        adyen_response_code: "soleProprietorship.registeredAddress.postalCode",
        main_header: "Invalid legal entity information provided",
        description: "Postal code not valid for US. Allowed formats: NNNNN or NNNNN-NNNN",
        section: "company",
        parentFieldRef: "solePropBusinessAddressRef",
        fields: ["postalCode", "zipCode"],
        remediatingActions: [
            {
                message: "Change postal/zip code.",
                type: "dataChange"
            }
        ]
    },
    {
        adyen_response_code: "bankAccount.bankCode",
        main_header: "Invalid legal entity information provided",
        description: "Institution number not present (ctboa_ca_ach)",
        section: "bankAccount",
        fields: ["institutionNumber"],
        remediatingActions: [
            {
                message: "Change bank account Institution Number",
                type: "dataChange"
            }
        ],
        errorInfos: bankAccountFileUploadInfo
    },
    {
        adyen_response_code: "company.object.empty",
        main_header: "Invalid company information provided",
        description: "Company object from KYC is empty.",
        section: "company",
        remediatingActions: [
            {
                message: "Add company information",
                type: "addData"
            }
        ]
    },
    {
        adyen_response_code: "company.name.invalid",
        main_header: "Invalid company information provided",
        description: "The company's name is empty.",
        section: "company",
        fields: ["legalBusinessName", "legalName", "individualFirstName", "individualLastName"],
        remediatingActions: [
            {
                message: "Add the company name",
                type: "addData"
            }
        ]
    },
    {
        adyen_response_code: "company.address.empty.street",
        main_header: "Invalid company information provided",
        description: "The company's 'street name' from the address is empty.",
        section: "company",
        parentFieldRef: "companyAddressRef",
        fields: ["street1", "street2"],
        remediatingActions: [
            {
                message: "Add the company street name",
                type: "addData"
            }
        ]
    },
    {
        adyen_response_code: "company.address.empty.city",
        main_header: "Invalid company information provided",
        description: "The company's 'city name' from the address is empty.",
        section: "company",
        parentFieldRef: "companyAddressRef",
        fields: ["city"],
        remediatingActions: [
            {
                message: "Add the company city name",
                type: "addData"
            }
        ]
    },
    {
        adyen_response_code: "company.address.empty.state.province",
        main_header: "Invalid company information provided",
        description: "The company's 'state or province' from the address is empty.",
        section: "company",
        parentFieldRef: "companyAddressRef",
        fields: ["stateOrProvince"],
        remediatingActions: [
            {
                message: "Add the company state or province name",
                type: "addData"
            }
        ]
    },
    {
        adyen_response_code: "company.address.postalCode",
        main_header: "Invalid company information provided",
        description: "The company's 'postal code' from the address is empty.",
        section: "company",
        fieldStep: 2,
        parentFieldRef: "companyAddressRef",
        fields: ["postalCode"],
        remediatingActions: [
            {
                message: "Add the company postal code",
                type: "addData"
            }
        ],
    },
    {
        adyen_response_code: "owner.firstName.empty",
        main_header: "The owner's First Name is empty.",
        description: "A required value was not specified: empty value",
        section: "owners",
        fields: ["firstName"],
        remediatingActions: [
            {
                message: "Add owner's First Name.",
                type: "addData"
            }
        ]
    },
    {
        adyen_response_code: "owner.lastName.empty",
        main_header: "The owner's Last Name is empty.",
        description: "A required value was not specified: empty value",
        section: "owners",
        fields: ["lastName"],
        remediatingActions: [
            {
                message: "Add owner's Last Name.",
                type: "addData"
            }
        ]
    },
    {
        adyen_response_code: "owner.email.empty",
        main_header: "The owner's E-mail is empty.",
        description: "A required value was not specified: empty value",
        section: "owners",
        fields: ["email"],
        remediatingActions: [
            {
                message: "Add owner's E-mail.",
                type: "addData"
            }
        ]
    },
    {
        adyen_response_code: "owner.birthdate.empty",
        main_header: "The owner's birth date is empty.",
        description: "A required value was not specified: empty value",
        section: "owners",
        fields: ["birthDate"],
        remediatingActions: [
            {
                message: "Add owner's Birthdate.",
                type: "addData"
            }
        ]
    },
    {
        adyen_response_code: "owner.birthdate.invalid",
        main_header: "The owner's birth date is invalid.",
        description: "The date is invalid",
        section: "owners",
        fields: ["birthDate"],
        remediatingActions: [
            {
                message: "Verify owner's Birthdate.",
                type: "addData",
                code: 'owner.birthdate.invalid'
            }
        ]
    },
    {
        adyen_response_code: "owner.phone.empty",
        main_header: "The owner's phone number is empty.",
        description: "A required value was not specified: empty value",
        section: "owners",
        fields: ["phone"],
        remediatingActions: [
            {
                message: "Add owner's Phone number.",
                type: "addData"
            }
        ]
    },
    {
        adyen_response_code: "owner.ssn.empty",
        main_header: "The owner's Social Security Number is empty.",
        description: "A required value was not specified: empty value",
        section: "owners",
        fields: ["socialSecurityNumber", "confirmSocialSecurityNumber"],
        remediatingActions: [
            {
                message: "Add owner's Social Security Number.",
                type: "addData"
            }
        ]
    },
    {
        adyen_response_code: "bankAccount.accountNumber.empty",
        main_header: "The bank account number is empty.",
        description: "Add bank account number",
        section: "bankAccount",
        fields: ["accountNumber"],
        remediatingActions: [
            {
                message: "Add bank account number",
                type: "addData"
            }
        ],
        errorInfos: bankAccountFileUploadInfo
    },
    {
        adyen_response_code: "bankAccount.routingNumber.empty",
        main_header: "The bank account routing number is empty.",
        description: "Add bank account routing number",
        section: "bankAccount",
        fields: ["routingNumber", "branchCode"],
        remediatingActions: [
            {
                message: "Add bank account routing number",
                type: "addData"
            }
        ],
        errorInfos: bankAccountFileUploadInfo
    },
    {
        adyen_response_code: "bankAccount.transitNumber.empty",
        main_header: "The bank account transit number is empty.",
        description: "Add bank account transit number",
        section: "bankAccount",
        fields: ["transitNumber"],
        remediatingActions: [
            {
                message: "Add bank account transit number",
                type: "addData"
            }
        ],
        errorInfos: bankAccountFileUploadInfo
    },
    {
        adyen_response_code: "proofOfOwnership.nonProfit.fileUpload.empty",
        main_header: "You need to upload a Proof of Ownership to submit.",
        description: "Upload the Proof of Ownership file",
        section: "company",
        fieldStep: 2,
        fields: ["proofOfOwnershipFileUpload"],
        uploadFile: 'proofOfOwnership',
        remediatingActions: [
            {
                message: "Upload the Proof of Ownership file",
                type: "uploadFile"
            }
        ]
    },
];

export const adyenErrorSections = [
    {
        label: "Remediation for general issues",
        value: "1_1"
    },
    {
        label: "Remediation for individual check issues",
        value: "1_3"
    },
    {
        label: "Remediation for organization check issues",
        value: "1_5"
    },
    {
        label: "Remediation for sole proprietorship issues",
        value: "1_6"
    },
    {
        label: "Remediation for bank account check issues",
        value: "1_7"
    },
    {
        label: "Remediation for bank account check issues",
        value: "2_8"
    },
    {
        label: "Remediation for PCI sign issues",
        value: "2_9"
    }
]

const getPortalErrorDescriptionFromAdyenError = (error) => {
    let found = verificationCodes.find((item) => item.adyen_response_code == error.code);

    let remediatingActions = error.remediatingActions ?? [error];
    let newRemediatingActions = [];
    remediatingActions.forEach((item, index) => {
        let documentUpload = item.message.startsWith('Upload');
        let dataChange = item.message.startsWith('Update') || item.message.startsWith('Use different');
        let type = null;
        if (documentUpload) type = 'documentUpload';
        if (dataChange) type = 'dataChange';

        if (found && found.overrideAdyenText) {
            item.message = found.short_text;
        }

        newRemediatingActions[index] = {
            ...item,
            type
        }
    })

    let unmappedError = {
        short_text: error.code,
        description: error.message,
        remediatingActions: newRemediatingActions
    }

    if (found) {
        found.remediatingActions = newRemediatingActions;
    }

    return found ?? unmappedError;
}

export const excludeRelatedIssues = (allIssues, reverse = false) => {
    let concatenated = allIssues;

    if (reverse) {
        concatenated = concatenated.reverse();
    }

    let issuesCodes = [];
    let addedIssuesCodes = [];
    let cleanIssues = [];
    let addIssue = true;
    for (let issue of concatenated) {
        let key = issue.remediatingActions ? 'remediatingActions' : 'subErrors';
        for (let item of issue[key]) {
            issuesCodes.push(item.code);
        }
    }

    for (let issue of concatenated) {
        if (issue.relatedTo) {
            for (let related of issue.relatedTo) {
                if (issuesCodes.includes(related)) {
                    addIssue = false;
                    break;
                }
            }
        }
        // Duplicate
        let key = issue.remediatingActions ? 'remediatingActions' : 'subErrors';
        for (let item of issue[key]) {
            if (addedIssuesCodes.includes(item.code)) {
                addIssue = false;
                break;
            }
            addedIssuesCodes.push(item.code);
        }


        if (addIssue) {
            cleanIssues.push(issue);
        }
    }

    return cleanIssues;
}

const getErrorsToInclude = (issue) => {
    // Include errors to trigger
    if (issue.includeErrors) {
        let errorsToInclude = [];
        for (let errorCode of issue.includeErrors) {
            let include = verificationCodes.find(item => item.adyen_response_code === errorCode);
            if (include) {
                errorsToInclude.push(include);
            }
        }

        return errorsToInclude;
    }

    return [];
}

export const getCompleteErrorsFromAdyenRequirements = (requirements, companyType = '') => {
    if (!requirements) {
        return [];
    }

    let errorsDescription = [];
    let errorsAlreadyAdded = [];
    for (let requirement of requirements) {
        let errorField = (requirement.subErrors && requirement.subErrors.length) ? 'subErrors' : 'remediatingActions';
        if (requirement[errorField]) {
            for (let error of requirement[errorField]) {
                addJavascriptHandlerToRequirementIfMapped(requirement, error);
                if (!errorsAlreadyAdded.includes(error.code)) {
                    const normalizeErrorDescription = getPortalErrorDescriptionFromAdyenError(error);
                    normalizeErrorDescription.entity = requirement.entity;
                    errorsDescription.push(normalizeErrorDescription);
                    errorsAlreadyAdded.push(error.code);
                }
            }
        }
    }

    if (companyType === 'soleProprietorship') {
        let include = [];
        for (let error of errorsDescription) {
            include = include.concat(getErrorsToInclude(error));
        }
        errorsDescription = excludeRelatedIssues(errorsDescription.concat(include), true);
    }

    return replaceErrorsForNonIndividuals(errorsDescription, companyType);
}

export const getErrorFromAdyenInvalidFields = (error) => {
    if (typeof error === 'string') {
        error = {
            invalidFields: [
                {
                    name: error
                }
            ]
        }
    }

    let errorsFound = [];

    for (let invalidField of error.invalidFields) {
        let newError = {
            name: invalidField.name,
            description: invalidField.message
        };
        if (invalidField.ownerIndex != null) {
            newError.ownerIndex = invalidField.ownerIndex;
        }
        errorsFound.push(newError);
    }

    if (errorsFound.length === 0) {
        return false;
    }
    let errorList = [];
    invalidFieldErrorCodes.forEach(registeredError => {
        const error = errorsFound.find(error => error.name === registeredError.adyen_response_code);
        if (error) {
            registeredError.description = (registeredError.description !== error.description)
                ? error.description
                : registeredError.description;
            registeredError = {...registeredError, ...error};
            errorList.push(registeredError);
        }
    });
    return errorList;
}

const replaceErrorsForNonIndividuals = (errorsDescription, companyType) =>
{
    if (companyType !== 'individual') {
        for (let error of errorsDescription) {
            const subErrorField = error['subErrors'] ? 'subErrors' : 'remediatingActions';

            // Check if any sub-error has the code '1_7012'
            const hasRegistrationError = error[subErrorField].some(subError => subError.code === '1_7012');

            if (hasRegistrationError) {
                // If '1_7012' is present, update error information
                error.errorInfos = registrationDocumentFileUploadInfo;
                error.remediatingActions = [
                    {
                        code: '1_501',
                        message: 'Upload a registration document',
                        type: 'documentUpload',
                    }
                ];
            }
        }
    }

    return errorsDescription;
}

/**
 * Sometimes, we can have the same remediating error code mapped to different Adyen error codes.
 * This is intended to check if we have this remediating action/sub-error mapped with javascript_error_handler.
 * Set the javascript_error_handler to the requirement if it is mapped.
 * https://docs.adyen.com/issuing/kyc-verification-codes/remediating-actions/
 */
const addJavascriptHandlerToRequirementIfMapped = (requirement, error) => {
    if (verificationCodes && !requirement.javascript_error_handler) {
        let remediatingActionErrorCodes = [];
        if (error.remediatingActions) {
            remediatingActionErrorCodes = error.remediatingActions;
        }
        if (error.subErrors && error?.subErrors?.remediatingActions) {
            remediatingActionErrorCodes = [...remediatingActionErrorCodes, ...error.subErrors.remediatingActions];
        }
        for (let remediatingAction of remediatingActionErrorCodes) {
            const remediatingActionMapped = verificationCodes.find((item) => item?.adyen_response_code === remediatingAction.code);
            if (remediatingActionMapped && remediatingActionMapped?.javascript_error_handler) {
                requirement.javascript_error_handler = remediatingActionMapped.javascript_error_handler;
            }
        }
    }
}

export const getInvalidFieldIssueFromAdyenResponseCode = (adyenResponseCode) => {
    return invalidFieldErrorCodes.find((item) => item.adyen_response_code == adyenResponseCode);
}