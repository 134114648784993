<template>
    <div class="content-container popup-container">
        <transition name="fade">
            <div
                v-show="showInfo && info"
                class="popup-content"
                @mouseover="handleShowInfo(true)"
                @mouseout="handleShowInfo(false)"
                ref="popupContent"
                :style="`margin-top: -${contentHeight}px; width: ${width}px`"
            >{{info}}</div>
        </transition>
        <i
            v-if="info"
            :class="bootstrapIconClass"
            @mouseover="handleShowInfo(true)"
            @mouseout="handleShowInfo(false)"
        />
    </div>
</template>

<script>
export default {
    name: "InfoMark",
    props: {
        info: { type: String, default: '' },
        width: {type: Number, default: 240},
        bootstrapIconClass: {type: String, default: 'bi bi-info-square-fill text-secondary'}
    },
    data: function() {
        return {
            contentHeight: 0,
            showInfo: false
        }
    },
    mounted() {
        this.setContentHeight();
    },
    methods: {
        handleShowInfo(display) {
            this.$nextTick(() => {
                this.showInfo = display;
                if (!this.showInfo) {
                    this.setContentHeight();
                }
            });
        },
        setContentHeight() {
            if (this.$refs.popupContent) {
                this.contentHeight = this.$refs.popupContent.offsetHeight;
                if (this.contentHeight > 0) {
                    this.contentHeight += 10;
                }
            }
        }
    }
}
</script>

<style scoped>
i {
    cursor: pointer;
}
.content-container {
    display: inline-block;
    margin-left: 3px;
    margin-right: 3px;
}

.popup-container {
    display: inline-block;
}

.popup-content {
    position: absolute;
    transform: translateX(-48%);
    background-color: #fff;
    color: #333;
    border-radius: 4px;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 3;
    font-size: 14px;
}

/* Style for the arrow */
.popup-content::before {
    content: "";
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: #f9f9f9 transparent transparent transparent;
}

/* Style for the arrow's inner triangle */
.popup-content::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>