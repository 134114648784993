import { createApp } from 'vue';
import Page from './components/Login.vue';

// Attach the event bus to the global properties for easy access
const app = createApp(Page);
app.config.devtools = false;

// Handle the window.showComponent logic after mounting
app.mixin({
    mounted() {
        if (window.showComponent) {
            window.eventHub.emit('hideLoginView');
            window.eventHub.emit(window.showComponent);
        }
        if (window.showChangePassword) {
            window.eventHub.emit(window.showChangePassword);
        }
    }
});

// Mount the app to the DOM
app.mount('#login');
