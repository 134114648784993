<template>
    <label-group :label="label"></label-group>
    <div id="otp-container" class="form-control p-0 border-0">
        <input
            class="otp-input"
            v-model="otpValue"
            inputmode="numeric"
            type="text"
            autocomplete="one-time-code"
            maxlength="6"
            pattern="\d{6}"
            min="000000"
            max="999999"
            @keydown.enter="(event) => handleKeyDown(event)"
            @paste="handlePaste"
        />
    </div>
</template>

<script setup>
import LabelGroup from "@/Vue/common/components/LabelGroup.vue";
import {computed, onMounted, ref} from "vue";

const emit = defineEmits(['change', 'modelValue']);

const props = defineProps({
    modelValue: {type: String, default: null},
    label: {type: String, default: ''},
    focus: {type: Boolean, default: true}
});

const otpValue = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    }
});

const handleKeyDown = (e) => {
    if (otpValue.value?.length === 6) {
        console.log('emit change ' + otpValue.value, e);
        emit('keydown', e);
    }
};

const handlePaste = () => {
    setTimeout(() => {
        if (otpValue.value?.length === 6) {
            emit('enter');
        }
    }, 0);
};

const inputRef = ref(null);

const focusInput = () => {
    if (!props.focus) {
        return;
    }
    if (inputRef.value) {
        inputRef.value.focus();
    }
};

onMounted(() => {
    const input = document.querySelector('[autocomplete=one-time-code]');
    inputRef.value = input;

    input.addEventListener('input', () => input.style.setProperty('--_otp-digit', input.selectionStart));

    // Create intersection observer to detect visibility
    const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                focusInput();
            }
        });
    }, { threshold: 0.1 });

    observer.observe(input);

    // Initial focus
    focusInput();
});

</script>
<style scoped>

:where([autocomplete=one-time-code]) {
    --otp-digits: 6;
    --otp-ls: 2ch;
    --otp-gap: 1.25;

    /* private consts */
    --_otp-bgsz: calc(var(--otp-ls) + 1ch);
    --_otp-digit: 0;

    all: unset;
    background:
        linear-gradient(90deg,
        var(--otp-bg, #BBB) calc(var(--otp-gap) * var(--otp-ls)),
        transparent 0),
        linear-gradient(90deg,
        var(--otp-bg, #EEE) calc(var(--otp-gap) * var(--otp-ls)),
        transparent 0
        );
    background-position: calc(var(--_otp-digit) * var(--_otp-bgsz)) 0, 0 0;
    background-repeat: no-repeat, repeat-x;
    background-size: var(--_otp-bgsz) 100%;
    caret-color: var(--otp-cc, #222);
    caret-shape: block;
    clip-path: inset(0% calc(var(--otp-ls) / 2) 0% 0%);
    font-family: ui-monospace, monospace;
    font-size: var(--otp-fz, 2.5em);
    inline-size: calc(var(--otp-digits) * var(--_otp-bgsz));
    letter-spacing: var(--otp-ls);
    padding-block: var(--otp-pb, 1ch);
    padding-inline-start: calc(((var(--otp-ls) - 1ch) / 2) * var(--otp-gap));
}

#otp-container {
    display: flex;
    justify-content: space-evenly;
}

</style>
