export default function(value, currency, cents) {
    // Clean number: only digits and only one decimal
    value = String(value).replace(/[^\d.]/g, "");
    value = value.split('.', 2).join('.');
    switch (currency) {
        case '$':
        case 'usd':
        default:
            currency = window.currencyIso || 'USD';
    }

    const country = window.countryCode || 'US';

    if (cents) {
        value = value / 100;
    }

    let formatter = new Intl.NumberFormat('en-' + country, {
        style: 'currency',
        currency,
    });

    return formatter.format(value);
}
