import axios from "axios";

if (!window.axios) {
    axios.interceptors.response.use(r => r, error => {
        if (error.response.status === 401 && error.response.data?.error === 'Please login.') {
            window.eventHub.$emit('sessionExpired');
        }

        return Promise.reject(error);
    });

    window.axios = axios;
}

export default window.axios;
