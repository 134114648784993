<template>
    <div class="card" :class="panelClasses" :style="{'border: none': !withBorder}">
        <div v-if="hasHeader && !headerType" class="panel-heading">
            <slot name="header" :header="header">
                {{ header }}
            </slot>
        </div>
        <div :id="myBodyId" class="card-body" :style="`padding: ${customPadding}`">
            <span v-if="hasHeader && headerType === 'h1'" class="h1">{{ header }}</span>
            <slot />
            <footer v-if="withFooter">
                <slot name="footer" />
            </footer>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Panel',
    props: {
        header: {type: String, default: null},
        headerType: {type: String, default: null},
        withHeader: {type: Boolean, default: false},
        withFooter: {type: Boolean, default: false},
        backGround: {type: String, default: null},
        theme: {type: String, default: 'default'},
        hidePrint: {type: Boolean, default: false},
        withBorder: {type: Boolean, default: true},
        customPadding: {type: String, default: '15px'}
    },
    computed: {
        myBodyId() {
            return `panel-body-${this._uid}`;
        },
        hasHeader() {
            return (this.withHeader || this.header);
        },
        themeClass() {
            return this.theme ? this.theme : null;
        },
        panelClasses() {
            const classes = [];

            if (this.themeClass) {
                classes.push(this.themeClass);
            }

            if (this.hidePrint === true) {
                classes.push('d-print-none');
            }

            if (this.backGround) {
                classes.push('text-bg-' + this.backGround);
            }

            return classes;
        },
    },
};
</script>

<style scoped>

.card {
    display: block;
    margin-bottom: 20px;
}

.card-body {
    padding: 15px;
}

.panel-default {
    border-color: #ddd;
}

.panel-heading {
    color: #333;
    background-color: #f5f5f5;
    border-color: #ddd;
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}


</style>
