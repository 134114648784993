import dateFormatter from "@/Vue/common/filters/dateFormatter";
import currencyFormatFn from "@/Vue/common/filters/currencyFormatter";
import {computed} from "vue";
import moment from "moment/moment";

export const isNotDate = (str) => {
    try {
        const date = new Date(str);
        return isNaN(date.getTime());
    } catch (e) {
        return false;
    }
}

export const formatPercent = (value) => {
    if (typeof value != 'number') {
        value = Math.floor(value.replace(/\D/g,''));
    }
    return value + '%';
};

export const formatMoney = (value) => {
    let sign = '$'

    let sanitized = value.replace(/[^0-9.]/g, '');
    if (typeof value != 'number') {
        value = (sanitized && isNaN(parseFloat(sanitized))) ? value : sanitized;
    }

    return value ? sign + value : value;
}

export const formatCardNumber = (cardNumber) => {
    let cleanedNumber = cardNumber.replace(/\D/g, '');
    let groups = cleanedNumber.match(/.{1,4}/g);
    let formattedNumber = groups.join(' ');

    return formattedNumber;
}

export const formatCardExpiryDate = (expiryDate) => {
    const digitsOnly = expiryDate.replace(/\D/g, '');
    const month = digitsOnly.slice(0, 2);
    const year = digitsOnly.slice(2, 4);
    const formattedExpiry = `${month}/${year}`;

    return formattedExpiry;
}

export const dateFormat = (date, format = 'LLL d, yyyy, h:mm:ss a') => {
    return dateFormatter(date, format, true);
};

export const dateTimeFormat = (date, format = 'LLL d, yyyy, h:mm:ss a') => {
    if (isNotDate(date)) {
        return date;
    }

    return dateFormatter(date, format, true);
};

export const currency = computed(() => window.currencyIso ? window.currencyIso : 'USD');

export const currencyFormat = (amount) => {
    return currencyFormatFn(amount, currency.value);
}

export const formatDateUsingMoment = (date, format = 'MM/DD/YYYY') => {
    if (!date || !(date instanceof Date) && typeof date !== 'string') return '';
    const momentDate = moment(date);
    if (momentDate.isValid()) {
        return momentDate.format(format);
    }
    return '';
};

/**
 * Adds trailing zeros to a number to match the specified number of decimal places.
 *
 * @param {number|string} input - The number or string to be formatted.
 * @param {number} [decimalPlaces=2] - The number of decimal places to ensure (default is 2).
 * @returns {string} The input number as a string, with trailing zeros added if necessary.
 */
export const addTrailingZeros = (input, decimalPlaces = 2) => {
    try {
        return parseFloat(input).toFixed(decimalPlaces);
    } catch (error) {
        return input;
    }
}

