import {defineStore} from "pinia";
import {ref} from "vue";

export const useOnboardingStore = defineStore('onboarding',  () => {
    const selectedIssueToSolve = ref(null);
    const ownerIndexWithError = ref(null);
    const ownerWithError = ref(null);

    const setSelectedIssueToSolve = (issue) => {
        selectedIssueToSolve.value = issue;
    }

    const getSelectedIssueToSolve = () => {
        return selectedIssueToSolve.value;
    }

    const setOwnerIndexWithError = (ownerIndex) => {
        ownerIndexWithError.value = ownerIndex;
    };

    const getOwnerIndexWithError = () => {
        return ownerIndexWithError.value;
    };

    const setOwnerWithError = (owner) => {
        ownerWithError.value = owner;
    };

    const getOwnerWithError = () => {
        return ownerWithError.value;
    };

    return {
        setSelectedIssueToSolve,
        getSelectedIssueToSolve,
        setOwnerIndexWithError,
        getOwnerIndexWithError,
        setOwnerWithError,
        getOwnerWithError
    }
})