<template>
    <div @click.stop="handleClick" class="m-1" :class="{'d-grid gap-2': blockButton, 'div-inline': !blockButton}">
        <button :id="myId" type="button" class="btn" :class="typeClass" @click.stop="handleClick" :disabled="computedDisabled">
            <span v-if="bootstrapIcon" :class="bootstrapIcon"></span>
            <slot :text="text">
                {{ text }}
            </slot>
            <span v-if="inProgress || loading"><i class="fa fa-spinner fa-pulse"></i></span>
            <span v-if="timeFeedback > 0">{{timeFeedback}}</span>
        </button>
    </div>
</template>

<script>

export default {
    name: "ActionButton",
    props: {
        text: {type: String, default: null},
        type: {type: String, default: 'primary'},
        blockButton: {type: Boolean, default: false},
        disabled: {type: Boolean, default: false},
        loading: {type: Boolean, default: false},
	    id: {type: String, default: null},
        activeTimeDelay: {type: Boolean, default: false},
        timeDelay: {type: Number, default: 10}, // delay in seconds to re-enable button
        bootstrapIcon: {type: String, default: ''},
        linkStyle: {type: Boolean, default: false}
    },
    data() {
        return {
            inProgress: false,
            isDisabled: false,
            timeFeedback: 0
        };
    },
    computed: {
        computedDisabled() {
              return (this.inProgress || this.disabled || this.isDisabled);
        },
        typeClass() {
            if (this.type && this.type.includes(',') > 0) {
                let types = '';
                const split = this.type.split(',');
                for (const t of split) {
                    types += ' btn-' + t;
                }
                return types.trim();
            }
            return this.type ? `btn-${this.type}` : null;
        },
        myId() {
            let id = this.id;
            if (!id) {
                id = `input-${this.$.uid}`;
            }
            return id;
        }
    },
    methods: {
        handleClick(event) {
            if (this.computedDisabled) {
                event.stopImmediatePropagation();
                return;
            }
            this.$emit('click');
            this.handleWaitingFeedback();
        },
        handleWaitingFeedback() {
            if (this.activeTimeDelay) {
                this.setInProgress(true);
                this.timeFeedback = this.timeDelay;
                this.timeInterval = setInterval(() => {
                    console.log(this.timeFeedback);
                    if (this.timeFeedback > 0) {
                        this.timeFeedback--;
                    } else {
                        this.setInProgress(false);
                        clearInterval(this.timeInterval);
                    }
                }, 1000);
            }
        },
        setInProgress(inProgress) {
            this.inProgress = inProgress;
        },
        setDisabled(inProgress) {
            this.isDisabled = inProgress;
        }
    }
}
</script>

<style scoped>
.btn-link {
    text-decoration: none;
    color: black;
}

.btn-light {
    background-color: white;
    border: solid 1px #ced4da;
}

.div-inline {
    display: inline-block;
}

.btn-underline-link {
    padding-left: 0px;
    text-decoration: underline!important;
    font-size: 10px;
    color: #7B7B7B;
}

.btn-medium-padding {
    padding: 8px 24px 8px 24px;
}
</style>
