<template>
    <div>
        <panel v-if="showVerified === true">
            Your email address was verified!
        </panel>
        <panel v-if="showVerified === false">
            Your email validation link was not valid, or it expired.
        </panel>
        <panel header="Account Login" header-type="h1" v-show="show">
            <error-details ref="errorDetails"></error-details>
            <text-input id="email-input" label="Email" placeholder="johndoe@test.com" v-model="username" name="email"></text-input>
            <password-input id="password-input" v-model="password"></password-input>
            <div class="form-group">
                <action-button id="submit-login" text="Login" type="primary,block" @click="login()"></action-button>
                <div class="help-block container-flex justify-content-between align-items-center">
                    <checkbox-input v-model="rememberMe" value="remember_me" label="Remember me"></checkbox-input>
                    <a @click="openForgotPassword()">Forgot Password</a>
                </div>
            </div>
        </panel>
        <forgot-password @closedForgotPassword="open"></forgot-password>
        <change-password @closedChangePassword="open"></change-password>
    </div>
</template>

<script>

import axios from "src/Vue/common/utilities/http";
import Panel from "src/Vue/common/components/Panel";
import ErrorDetails from "src/Vue/common/components/AlertDetails";
import TextInput from "src/Vue/common/components/TextInput";
import PasswordInput from "src/Vue/common/components/PasswordInput";
import ActionButton from "src/Vue/common/components/ActionButton";
import CheckboxInput from "src/Vue/common/components/CheckboxInput";
import ForgotPassword from "src/Vue/login/components/ForgotPassword";
import ChangePassword from "src/Vue/login/components/ChangePassword";

export default {
    name: 'Login',
    components: {ChangePassword, ForgotPassword, CheckboxInput, ActionButton, PasswordInput, TextInput, ErrorDetails, Panel},
    props: {
        onAuthSuccess: {
            type: Function,
            required: false,
            default: function (loginResponse) {
                window.location = loginResponse?.redirectTo ?? '/terminal';
            }
        }
    },
    data() {
        return {
            username: null,
            password: null,
            companyId: window.companyId,
            errorMessage: null,
            rememberMe: false,
            show: true,
            showVerified: null
        }
    },
    mounted() {
        let stored = localStorage.getItem('remember_me');
        if (stored) {
            this.username = stored;
            this.rememberMe = true;
        }
        window.eventHub.$on('hideLoginView', this.close);
        window.eventHub.$on('showLoginView', this.open);

        const urlParams = new URLSearchParams(window.location.search);
        let queryParamShowVerify = urlParams.get('verified');
        if (queryParamShowVerify === 'true') {
            this.showVerified = true;
        } else if (queryParamShowVerify === 'false') {
            this.showVerified = false;
        }
    },
    methods: {
        login() {
            this.$refs.errorDetails.reset();
            if (!this.username) {
                this.$refs.errorDetails.addDetail('Invalid email');
            }
            if (!this.password) {
                this.$refs.errorDetails.addDetail('Invalid password');
            }
            if (!this.username || !this.password) {
                this.$refs.errorDetails.setErrorMessage('Please enter valid login credentials');
                return;
            }

            let params = {
                email: this.username,
                password: this.password,
            };

            if (this.rememberMe) {
                localStorage.setItem('remember_me', this.username);
            } else {
                localStorage.removeItem('remember_me');
            }
            axios.post('/api/login', params).then((response) => {
                if (response.data.error) {
                    this.$refs.errorDetails.setErrorMessage(response.data.msg);
                } else if (response.data.user_id > 0) {
                    this.onAuthSuccess(response.data)
                }
            }, (error) => {
                this.$refs.errorDetails.setErrorMessage(error.response.data.msg);
            });
        },
        open() {
            this.show = true;
        },
        close() {
            this.show = false;
        },
        openForgotPassword() {
            window.eventHub.$emit('showForgotPassword');
            this.close();
        },
    }
}
</script>

<style>

</style>
