<template>
    <div @click.stop="$emit('click')" class="checkbox" :class="classes">
        <label :style="cssVars" :class="labelClasses">
            <input :id="myId" class="checkbox-input" v-model="model" type="checkbox" :disabled="disabled">
            <span v-if="renderHtml" v-html="label"></span>
            <span v-else>{{ label }}</span>
        </label>
    </div>
</template>

<script>

export default {
    name: "CheckboxInput",
    props: {
        modelValue: {type: [Boolean, Array], default: false, required: false},
        disabled: {type: Boolean, required: false, default: false},
        label: {type: String, default: null},
        noMargin: {type: Boolean, default: false},
        align: {type: String, default: 'center'},
        itemValue: {type: [String, Number], default: ''},
        labelColor: {type: String, default: ''},
        renderHtml: {type: Boolean, default: false},
        id: {type: String, default: null}
    },
    methods: {
        change() {
            let data = { value: this.label, checked: !this.modelValue }
            this.$emit('change', data);
        }
    },
    computed: {
        myId() {
            let id = this.id;
            if (!id) {
                id = `input-${this.$.uid}`;
            }
            return id;
        },
        model: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
                this.change();
            },
        },
        alignClass() {
            return `align-${this.align}`;
        },
        classes() {
            return [
                this.noMargin ? 'no-margin' : '',
                this.label ? '' : 'no-label',
                this.alignClass
            ];
        },
        labelClasses() {
            return [
                this.labelColor ? 'label-colored' : ''
            ]
        },
        cssVars() {
            return {
                '--label-color': this.labelColor,
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.checkbox {
    display: flex;
    align-items: center;
    padding: 0;

    &.no-margin {
        margin: 0;
    }

    label {
        height: unset;
        cursor: pointer;

        input[type='checkbox'] {
            width: 15px;
            height: 15px;
            cursor: pointer;
            vertical-align: middle;
            position: relative;
            bottom: .08em;
        }

        &.label-colored {
          color: var(--label-color)!important;
        }

    }

    &.no-label {
        justify-content: center;

        label {
            padding: 0;

            input {
                margin: 0;
            }
        }
    }

    &.align-left {
        justify-content: left;
    }
}

.checkbox-input {
    margin-right: 5px;
}
</style>
