export default {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            // here I check that click was outside the el and his children
            if (!(el === event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value
                //vnode.context[binding.expression](event);
                // vuejs 3
                binding.value(event, el);
            }
        };
        document.body.addEventListener('mousedown', el.clickOutsideEvent)
    },
    unbind: function (el) {
        document.body.removeEventListener('mousedown', el.clickOutsideEvent)
    },
}