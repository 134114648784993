<template>
    <div :class="{'d-flex align-baseline': showEditButton}">
        <label-group @view="() => handleShowPassword()" :id="myId" :label="computedLabel" :addon="addon" :currency-symbol="currencySymbol" :has-error="showError" :error-message="errorMessage" :class="classes" :horizontal="horizontal" :label-columns="labelColumns" :bold="labelBold" :label-description-info="inputDescriptionInfo">
            <div v-if="inputHint" class="input-hint-container">
                {{inputHint}}
            </div>
            <input v-if="myType === 'password'" v-bind:class="{'border border-danger': invalidValue}"
                   ref="textInput"
                   :id="myId"
                   :name="name"
                   v-model="model"
                   :type="passwordType"
                   class="form-control"
                   :placeholder="placeholder"
                   :style="styles"
                   @blur="blurred()"
                   @keyup.enter="handleEnter"
                   @keydown="checkAllowedContent"
                   autocomplete="new-password"
                   :disabled="disabled"
            >
            <input v-bind="maxInputLength" v-if="myType !== 'textarea' && myType !== 'password'"
                   :class="{'border border-danger': invalidValue, 'mb-0': showError}"
                   @keydown="checkAllowedContent"
                   ref="textInput"
                   :id="myId"
                   :name="name"
                   v-model.trim="model"
                   :type="myType"
                   class="form-control"
                   :placeholder="placeholder"
                   :style="styles"
                   @blur="blurred()"
                   @keyup="setVisited()"
                   @paste="handlePasting"
                   @keyup.enter="handleEnter"
                   autocomplete="new-password"
                   :disabled="disabled || editInput"
            >
            <textarea v-if="myType === 'textarea'"
                      :id="myId"
                      :name="name"
                      v-model="model"
                      class="form-control"
                      :placeholder="placeholder"
                      :style="styles"
                      rows="4"
                      @blur="$emit('blur', value)"
            ></textarea>
        </label-group>
        <i class="bi bi-pencil-square ms-2 mt-1" v-if="showEditButton && !editing" @click="handleEditInput"></i>
    </div>
</template>

<script>
import LabelGroup from "@/Vue/common/components/LabelGroup.vue";
import currencyCode from '@/Vue/common/utilities/currencyCode';
import {addTrailingZeros} from "@/Vue/common/utilities/formaters";

export default {
    name: "TextInput",
    components: {LabelGroup},
    props: {
        modelValue: {type: [String, Number], default: null},
        label: {type: String, default: null},
        labelColumns: {type: [String, Number], default: 4},
        labelBold: {type: Boolean, default: false},
        name: {type: String, default: null},
        type: {type: String, default: null},
        placeholder: {type: String, default: null},
        hasError: {type: Boolean, default: false},
        maxWidth: {type: [String, Number], default: 0},
        maxLength: {type: [String, Number], default: 0},
        desiredWidth: {type: String, default: null},
        addon: {type: String, default: null},
        currencySymbol: {type: String, default: currencyCode()},
        id: {type: String, default: null},
        inline: {type: Boolean, default: false},
        isRequired: {type: Boolean, default: false},
        inputHint: {type: String, default: ""},
        disabled: {type: Boolean, default: false},
        padBottom: {type: [String, Number], default: 0},
        allowedContent: {type: Array, default: () => {
            return ["string"];
        }},
        validationFns: {type: Array, default: () => {
            return [];
        }},
        minContentLength: {type: Number, default: -1},
        formatFn: {type: Function, default: (value) => { return value }},
        horizontal: {type: Boolean, default: false},
        showEditButton: {type: Boolean, default: false},
        preventPasting: {type: Boolean, default: false},
        labelClassName: {type: String, default: ''},
        inputDescriptionInfo: {type: [String, Object], default: null},
        showRequiredMark: {type: Boolean, default: false},
        requiredMark: {type: String, default: '*'}
    },
    data() {
        return {
            was_visited: false,
            invalidValue: false,
            customFieldErrorMessage: '',
            editing: false,
            temporaryType: false
        }
    },
    watch: {
        formatFn: function(formatter) {
            this.updateValue(formatter(this.modelValue));
        }
    },
    computed: {
        computedLabel() {
            return this.showRequiredMark && this.isRequired ? `${this.label}${this.requiredMark}` : this.label;
        },
        passwordType() {
            return this.temporaryType ? this.temporaryType : this.myType;
        },
        maxInputLength() {
            if (this.maxLength > 0) {
                return {maxlength: this.maxLength};
            }
            return {};
        },
        editInput() {
            return this.showEditButton && !this.editing;
        },
        myId() {
            let id = this.id;
            if (!id) {
                id = `input-${this.$.uid}`;
            }
            return id;
        },
        myType() {
            let type = 'text';
            if (this.type === 'password') {
                type = this.type;
            }
            if (this.type === 'textarea') {
                type = this.type;
            }
            return type;
        },
        classes() {
            let classes = {};
            if (this.inputHint) {
                classes['hint-input-type'] = true;
            }
            classes['pb-' + this.padBottom] = true;

            if (this.labelClassName) {
                classes[this.labelClassName] = true;
            }

            return classes;
        },
        styles() {
            let styles = {};
            if (this.maxWidth) {
                styles['maxWidth'] = this.maxWidth + 'px';
            }
            if (this.desiredWidth) {
                styles['width'] = this.desiredWidth + 'px';
            }
            if (this.inline) {
                styles['display'] = 'inline-block'
            }
            return styles;
        },
        requiredError() {
            return (this.isRequired && this.was_visited && !this.modelValue);
        },
        hasValidationError() {
            let errors = [];
            let hasError = false;
            for(let fn of this.validationFns) {
                if(typeof fn === 'function') {
                    let {valid, message} = fn(this.modelValue);
                    if(!valid) {
                        hasError = true;
                        errors.push(message);
                    }
                }
            }
            let errorsMessage = errors.join();
            return {hasError, errorsMessage};
        },
        showError() {
            let {hasError} = this.hasValidationError;
            return ((this.hasError || this.requiredError || hasError || this.invalidValue) && this.was_visited);
        },
        errorMessage() {
            let {hasError, errorsMessage} = this.hasValidationError;
            if (this.requiredError) {
                return 'This field is required';
            }
            if (hasError) {
                return errorsMessage;
            }

            if (this.customFieldErrorMessage) return this.customFieldErrorMessage;

            return null;
        },
        isFormElement() {
            return true;
        },
        isValid() {
            let {hasError} = this.hasValidationError;
            if(hasError) {
                return !hasError;
            }
            return (!this.isRequired || this.modelValue);
        },
        isError() {
            return (!this.isValid && this.isRequired);
        },
        model: {
            get() {
                return this.modelValue;
            },
            set(value, oldValue) {
                this.updateValue(value);
            },
        },
    },
    mounted() {
        this.updateValueBasedOnAddon();
    },
    methods: {
        updateValueBasedOnAddon() {
            if (this.addon !== 'currency') return;

            this.$nextTick(() => {
                const currencyValue = addTrailingZeros(this.modelValue ?? 0);
                this.$emit('input', currencyValue);
                this.$emit('update:modelValue', currencyValue);
            });
        },
        handleShowPassword() {
            this.temporaryType = this.temporaryType ? false : 'text';
        },
        handlePasting(event) {
            if (this.preventPasting) {
                event.preventDefault();
            }
        },
        focus() {
            this.$refs.textInput.focus();
        },
        async handleEnter() {
            if (this.showEditButton && this.editing) {
                this.editing = false;
            }
            this.$emit('enterkey', this.$refs.textInput.value);
            if (this.addon !== 'currency') {
                this.updateValue(this.formatFn(this.value));
            }
        },
        handleEditInput(event) {
            event.preventDefault();
            event.stopPropagation();
            this.editing = true;
        },
        updateValue(value) {
            this.$emit('change');
            this.$emit('update:modelValue', value);
            // SummaryWell fix
            this.$emit('input', value);
        },
        blurred() {
            const textValue = this.$refs?.textInput?.value ?? '';
            if (this.addon === 'currency') {
                let currencyValue = addTrailingZeros(textValue ?? 0);
                if (currencyValue !== textValue) {
                    this.$emit('update:modelValue', currencyValue);
                }
            } else {
                this.updateValue(this.formatFn(textValue));
            }
            this.$emit('blur', textValue);
            this.setVisited();
        },
        setVisited() {
            this.was_visited = true;
        },
        reset() {
            this.was_visited = false;
        },
        setInvalidValue() {
            this.setVisited();
            this.invalidValue = true;
        },
        setCustomInvalidMessage(message) {
            this.customFieldErrorMessage = message;
        },
        checkAllowedContent(event) {
            const numbersAllowed = new Set(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']);
            const currencyAllowed = new Set(['.']);
            const controlKeysAllowed = new Set(['Meta', 'Home', 'End', 'Tab', 'Control', 'Backspace', 'Delete', 'ArrowLeft', 'ArrowUp', 'ArrowDown', 'ArrowRight']);

            let input = event.key;
            if (input) {
                // Allow control keys
                if (controlKeysAllowed.has(input) || event.ctrlKey || event.metaKey) {
                    return;
                }

                if (this.allowedContent.includes('number') && !numbersAllowed.has(input)) {
                    event.preventDefault();
                    event.stopPropagation();
                }

                if (this.allowedContent.includes('currency') && !numbersAllowed.has(input) && !currencyAllowed.has(input)) {
                    event.preventDefault();
                    event.stopPropagation();
                }
            }
        }
    }
}
</script>

<style scoped>
i {
    cursor: pointer;
}

.hint-input-type {
  display: flex;
  margin-right: 10px;
}

.input-hint-container {
  display: flex;
  background-color: #ebebeb;
  color: black;
  padding: 3px;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 2px;
  border: solid 1px gray;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  min-width: 35px;
}

.form-control {
    margin-bottom: 0px;
}

</style>
