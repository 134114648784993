<template>
    <div :class="{'pb-2': hasError, 'form-group': horizontal}">
        <div class="d-flex align-items-baseline">
            <label v-if="label" :for="myId" class="form-label" :class="classes" style="margin-bottom: 0; margin-top: 8px;">{{ label }}</label>
            <div v-if="labelDescriptionInfo" :class="labelDescriptionClass">
                <info-mark v-if="labelDescriptionType === 'popup-info'" :info="labelDescriptionText" :bootstrap-icon-class="labelDescriptionIconClass" :width="labelDescriptionWidth"></info-mark>
                <span class="caption text-grey" v-else>{{labelDescriptionText}}</span>
            </div>
        </div>
        <slot v-if="!addon"></slot>
        <div v-if="addon" class="input-group">
            <span class="input-group-addon" :id="'addon' + myId">
                <span v-if="addon === 'currency' && currencySymbol !== null">{{ currencySymbol }}</span>
                <span v-if="addon === 'percent'">%</span>
                <span v-if="addon === 'calendar'" class="fa fa-calendar"></span>
                <span v-if="addon === 'view'" class="bi bi-eye-fill pointer" @click="$emit('view')"></span>
            </span>
            <slot></slot>
        </div>
        <div v-if="showError" class="error-container">
            <i style="color: #e0476d" class="bi bi-exclamation-triangle-fill"></i>
            {{errorMessage}}
        </div>
    </div>
</template>

<script>
import currencyCode from '@/Vue/common/utilities/currencyCode';
import InfoMark from "@/Vue/common/components/InfoMark.vue";

export default {
    name: "LabelGroup",
    components: {InfoMark},
    props: {
        label: {type: String, default: null},
        hasError: {type: Boolean, default: false},
        errorMessage: {type: String, default: null},
        addon: {type: String, default: null},
        currencySymbol: {type: String, default: currencyCode()},
        id: {type: String, default: null},
        horizontal: {type: Boolean, default: false},
        bold: {type: Boolean, default: false},
        labelColumns: {type: [String, Number], default: 4},
        labelDescriptionInfo: {type: [Object, String], default: null}
    },
    computed: {
        labelDescriptionClass() {
            if (typeof this.labelDescriptionInfo === 'object') {
                return this.labelDescriptionInfo.className;
            }

            return 'label-info-container';
        },
        labelDescriptionText() {
            if (this.labelDescriptionInfo?.text) {
                return this.labelDescriptionInfo.text;
            }

            return this.labelDescriptionInfo;
        },
        labelDescriptionType() {
            if (this.labelDescriptionInfo?.type) {
                return this.labelDescriptionInfo.type;
            }

            return 'text';
        },
        labelDescriptionIconClass() {
            if (this.labelDescriptionInfo?.iconClass) {
                return this.labelDescriptionInfo.iconClass;
            }

            return null;
        },
        labelDescriptionWidth() {
            if (this.labelDescriptionInfo?.width) {
                return this.labelDescriptionInfo.width;
            }

            return null;
        },
        myId() {
            let id = this.id;
            if (!id) {
                id = `input-${this.$.uid}`;
            }
            return id;
        },
        showError() {
            return (this.hasError && !!this.errorMessage);
        },
        classes() {
            let classes = {};
            if (this.horizontal && this.labelColumns > 0 && this.labelColumns < 11) {
                classes['col-sm-' + this.labelColumns] = true;
            }
            if (this.bold) {
                classes['fw-bold'] = true;
            }
            if (this.hasError) {
                classes['text-with-error'] = true;
            }
            return classes;
        }
    },
}
</script>

<style scoped>

.input-group-addon {
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 4px;
    vertical-align: middle;
    width: 55px!important;
}

.input-group-addon:first-child {
    border-right: 0;
}

.has-error {
    padding-bottom: 5px;
}

.error-container {
    padding: 3px;
    background-color: #ffeeee;
    color: #A94442;
}

.text-with-error {
    color: #A94442;
}

.label-info-container {
    display: flex;
    align-items: center;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: solid 1px black;
    font-size: 12px;
    text-align: center;
    justify-content: center;
    margin: 8px;
    font-weight: 400;
}

.pointer {
    cursor: pointer;
}

</style>
