import {format, toDate, isValid} from 'date-fns';
import {formatInTimeZone} from 'date-fns-tz';
import parse from 'date-fns/parse';
import parseISO from 'date-fns/parseISO';

export default function(value, newFormat, addTimeZone = false) {
    if (!newFormat) {
        newFormat = 'LLL d, yyyy';
    }
    if (value) {
        let date = null;

        if (!isNaN(value)) {
            let smallDate = new Date(2000, 1);
            if (value < smallDate.getTime()) {
                value *= 1000;
            }
            date = toDate(value);
        }
        if (!date) {
            let a = new Date();
            let test = parse(value, 'yyyy-MM-dd', a);

            if (isValid(test)) {
                date = test;
            } else {
                test = parse(value, 'yyyy-MM-dd kk:mm:ss', a);
                if (isValid(test)) {
                    date = test;
                } else {
                    test = parse(value, 'yyyy-MM-dd HH:mm:ss', a);
                    if (isValid(test)) {
                        date = test;
                    } else {
                        test = parseISO(value, a);
                        if (test) {
                            date = test;
                        }
                    }
                }
            }
        }

        if (isValid(date)) {
            let dateResult = format(date, newFormat);
            if (addTimeZone) {
                const timeZone = window.accountTimezone;
                if (timeZone) {
                    dateResult = formatInTimeZone(date, timeZone, newFormat + ' zzz');
                } else {
                    let timezone = new Date(dateResult).toLocaleTimeString(navigator.locale, {timeZoneName: 'short'}).split(' ')[2];
                    dateResult += ` ${timezone}`;
                }
            }
            return dateResult;
        }

        return '';
    }
}
