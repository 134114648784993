export default function(value, currency, cents) {
    switch (currency) {
        case '$':
        case 'usd':
        default:
            currency = window.currencyIso || 'USD';
    }

    const country = window.countryCode || 'US';

    if (cents) {
        value = value / 100;
    }

    let formatter = new Intl.NumberFormat('en-' + country, {
        style: 'currency',
        currency,
    });

    return formatter.format(value);
}
