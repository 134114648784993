<template>
    <panel header="Forgot Password?" header-type="h1" v-show="show">
        <error-details ref="errorDetails"></error-details>
        <text-input label="Email" placeholder="johndoe@test.com" v-model="email" name="email"></text-input>
        <div class="form-group">
            <action-button text="Send Reset Email" @click="sendEmail()" ref="sendEmail"></action-button>
            <action-button text="Cancel" type="default" @click="close()"></action-button>
        </div>
    </panel>
</template>

<script>

import Modal from "src/Vue/common/components/Modal";
import Panel from "src/Vue/common/components/Panel";
import ErrorDetails from "src/Vue/common/components/AlertDetails";
import TextInput from "src/Vue/common/components/TextInput";
import ActionButton from "src/Vue/common/components/ActionButton";
import axios from "src/Vue/common/utilities/http";

export default {
    name: "ForgotPassword",
    components: {Modal, Panel, TextInput, ActionButton, ErrorDetails},
    data() {
        return {
            email: null,
            show: false,
            sent: false,
        }
    },
    mounted() {
        window.eventHub.$on('showForgotPassword', () => { this.open(); });
    },
    methods: {
        sendEmail() {
            this.$refs.errorDetails.reset();
            if (!this.email) {
                this.$refs.errorDetails.setErrorMessage('Invalid email');
                return;
            }

            this.$refs.sendEmail.setInProgress(true);
            axios.post('/api/user/forgot_password/' + this.email).then((response) => {
                this.sent = true;
                this.$refs.sendEmail.setInProgress(false);
                this.$refs.errorDetails.setSuccessMessage('An email link has been sent to the requested email address');
            });
        },
        open() {
            this.show = true;
            if (window.forgotPasswordMessage) {
                this.$refs.errorDetails.setErrorMessage(window.forgotPasswordMessage);
            }
        },
        close() {
            this.$emit('closedForgotPassword');
            this.show = false;
        }
    }
}
</script>

<style scoped>

</style>
