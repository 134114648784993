<template>
    <div class="form-group">
        <text-input v-bind:label="label" placeholder="password" type="password" v-model="model" name="password"></text-input>
    </div>
</template>

<script>
import TextInput from "src/Vue/common/components/TextInput";

export default {
    name: "PasswordInput",
    components: {TextInput},
    props: {
        label: {type: String, default: 'Password'},
    },
    computed: {
        model: {
            get() {
                return null;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    }
}
</script>

<style scoped>

</style>