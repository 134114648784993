export default {
    beforeMount(el, binding) {
        el.clickOutsideEvent = function (event) {
            // Check if the click was outside the element and its children
            if (!(el === event.target || el.contains(event.target))) {
                // Call the method provided in the binding value
                binding.value(event, el);
            }
        };
        document.body.addEventListener('mousedown', el.clickOutsideEvent);
    },
    unmounted(el) {
        document.body.removeEventListener('mousedown', el.clickOutsideEvent);
    },
};
